/* eslint-disable react/jsx-no-bind */
import { useState, useEffect } from 'react';
import { Skeleton, Grid, Pagination, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import ShowTotals from '../components/showTotals/ShowTotals';
import '../styles/ObjectPage.css';
import { setStartIndex } from '../store/allObjects';
import EstateBigTile from '../components/estateBigTile/EstateBigTile';

const ObjectList = () => {
  const dispatch = useDispatch();

  const limit = useSelector((state) => state?.objects?.limit);
  const objectObject = useSelector((state) => state.objects.objects);
  const isLoading = useSelector((state) => state?.objects?.isLoading);
  const startIndex = useSelector((state) => state?.objects?.startIndex);
  const rowsData = useSelector((state) => state?.objects?.rows);
  const flag = useSelector((state) => state?.flag);
  const data = rowsData;
  const totalQty = objectObject?.total || 0;
  const grandTotalQty = objectObject?.grandTotal;

  const pagesQty = Math.ceil(totalQty / limit);
  const [page, setPage] = useState(startIndex / limit + 1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(setStartIndex(value * limit - limit));
  };

  const decrementStartIndex = () => {
    if (startIndex >= limit) {
      dispatch(setStartIndex(startIndex - limit));
      setPage(page - 1);
    }
  };

  const incrementStartIndex = () => {
    if (startIndex + limit <= totalQty) {
      dispatch(setStartIndex(startIndex + limit));
      setPage(page + 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <button className='btn-left' onClick={decrementStartIndex} type='button' />
      <button className='btn-right' onClick={incrementStartIndex} type='button' />
      <div className='object-wrapper'>
        <div className='skeleton-cont'>
          <ShowTotals flag={flag} grandTotalQty={grandTotalQty} page='Objects' totalQty={totalQty} />
        </div>
        {isLoading && <Skeleton />}
        {data ? (
          <>
            <div style={{ minHeight: '80vh' }}>
              <Grid container spacing={2} sx={{ position: 'relative' }}>
                {data.map((el) => (
                  <Grid key={el._id} item lg={3} md={4} sm={6} xl={3} xs={12}>
                    <EstateBigTile home={el} />
                  </Grid>
                ))}
              </Grid>
            </div>
            <Stack spacing={2} sx={{ pt: 3 }}>
              <Pagination
                count={pagesQty}
                onChange={handleChange}
                page={page}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Stack>
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>
    </>
  );
};

export default ObjectList;
