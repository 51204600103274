/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const sortObjectSlice = createSlice({
  name: 'sortObject',
  initialState: {},
  reducers: {
    addSortObject: (state, action) => action.payload,
  },
});

export default sortObjectSlice.reducer;
export const { addSortObject, removeSortObject } = sortObjectSlice.actions;
