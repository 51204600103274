import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#333333' },
    secondary: { main: '#7E7CCF' },
    common: { main: '#3d3d3d' },
  },
});

export default theme;
