import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import { clearRows } from '../store/allObjects';
import { clearRows as clearAllOffers } from '../store/allOffers';
import { getTable } from '../api/getTable';
import { getFavs } from '../api/getFavs';
import { getObjects } from '../api/getObject';
import ObjectPage from './ObjectPage';
import OffersPage from './OffersPage';
import Favourites from './Favourites';
import '../styles/UnitedPage.css';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const UnitedPage = () => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state?.objects?.tabNum);
  const filters = useSelector((state) => state.filtersObject);
  const limit = useSelector((state) => state?.objects?.limit);
  const sort = useSelector((state) => state.sortObject);
  const startIndex = useSelector((state) => state?.objects?.startIndex);

  useEffect(() => {
    if (filters.length || Object.keys(sort).length) {
      dispatch(clearRows());
      dispatch(clearAllOffers());
    }
    dispatch(getObjects({ filters, sort, offset: startIndex, limit }));
    dispatch(getTable({ filters, sort }));
    dispatch(getFavs());
  }, [filters, sort, startIndex]);

  // useEffect(() => {
  //   dispatch(getFavs());
  //   if (filters.length || Object.keys(sort).length) dispatch(clearRows());
  // }, [filters, sort]);

  return (
    <Box sx={{ width: '100%' }}>
      <TabPanel index={0} value={value}>
        <ObjectPage />
        <div className='emptyHeight' />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <OffersPage />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <Favourites />
        <div className='emptyHeight' />
      </TabPanel>
    </Box>
  );
};

export default UnitedPage;
