import * as React from 'react';
import { Menu } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import getAutocomplete from '../../../api/getAutoComplete';
import FilterMenu from '../filterMenu/FilterMenu';
import { TransparentButton, StyledBadge } from './FilterDropdownStyles';
import { addFilterObject, removeFilterObject } from '../../../store/filterObjectSlice';
import { addFilterLog, removeFilterLog } from '../../../store/filterLogSlice';
import { getFiltersLogs, getFiltersOffer } from '../../../api/getFilters';
import './FilterDropdown.css';
import { initialValues } from './InitialValues';
import filterBtn from '../../../images/filter.svg';

const FilterButton = () => {
  const location = window.location.pathname;
  const [typing, setTyping] = React.useState(false);
  const [completeOpts, setCompleteOpts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const filterOptionsObjects = useSelector((state) => state.objects?.objects?.filterOptions);
  const filterOptionsLog = useSelector((state) => state.logs?.logs?.filterOptions);

  const filterObject = useSelector((state) => state.filtersObject);
  const filterLog = useSelector((state) => state.filtersLog);
  const dataLink = '/data';

  const filterUserSettings = location === dataLink ? filterObject : filterLog;

  const filterOptions = location === dataLink ? filterOptionsObjects : filterOptionsLog;
  const filterFeatsGetter = location === dataLink ? getFiltersOffer : getFiltersLogs;

  const addToState = location === dataLink ? addFilterObject : addFilterLog;
  const removeFromState = location === dataLink ? removeFilterObject : removeFilterLog;

  const filterFeatsOffer = useSelector((state) => state.filterFeatsOffer.feats);
  const filterFeatsLog = useSelector((state) => state.filterFeatsLog.feats);
  const flag = useSelector((state) => state?.flag);

  const filterFeats = location === dataLink ? filterFeatsOffer : filterFeatsLog;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterLength = filterUserSettings?.length;
  const logged = useSelector((state) => state.logged);

  React.useEffect(() => {
    if (logged && flag) {
      dispatch(filterFeatsGetter());
    }
  }, [filterFeatsGetter]);

  const formik = useFormik({
    initialValues: initialValues(filterOptions, filterUserSettings, filterFeats),
    enableReinitialize: true,
    onSubmit: (values) => {
      const filters = Object.keys(values).reduce((acc, key) => {
        if (typeof values[key] === 'string' && values[key]) {
          acc.push({ key, value: [values[key].replace(/\.+$/, '')] });
        } else if (values[key]?.length > 0) {
          acc.push({ key, value: values[key] });
        } else if (values[key]?.min && !values[key]?.max) {
          acc.push({ key, value: { min: parseInt(values[key].min.toString().replace(/\D/g, ''), 10) } });
        } else if (!values[key]?.min && values[key]?.max) {
          acc.push({ key, value: { max: parseInt(values[key].max.toString().replace(/\D/g, ''), 10) } });
        } else if (values[key]?.min && values[key]?.max) {
          acc.push({
            key,
            value: {
              min: parseInt(values[key].min.toString().replace(/\D/g, ''), 10),
              max: parseInt(values[key].max.toString().replace(/\D/g, ''), 10),
            },
          });
        }
        return acc;
      }, []);
      dispatch(addToState(filters));
    },
  });

  const clearFilter = () => {
    formik.resetForm();
    dispatch(removeFromState());
  };

  React.useEffect(async () => {
    if (formik?.values?.name && formik.values?.name?.length >= 3) {
      setLoading(true);
      const res = await getAutocomplete({ key: 'name', text: formik.values.name });
      setCompleteOpts(res);
      setLoading(false);
    }
  }, [formik.values.name]);

  React.useEffect(() => {
    if (formik?.initialValues?.name !== formik?.values?.name && formik?.values?.name?.length >= 3 && !typing) {
      setTimeout(() => {
        formik.handleSubmit();
      });
    }
    if (formik.initialValues !== formik.values && !typing) {
      setTimeout(() => {
        formik.handleSubmit();
      });
    }
  }, [formik.initialValues, formik.values, typing]);

  return (
    <div className='button-container'>
      <div className='button-cover'>
        <TransparentButton id='filter-button' onClick={handleClick} size='large' variant='outlined'>
          <StyledBadge badgeContent={filterLength} color='primary' overlap='circular' sx={{ alignItems: 'center' }}>
            <span className='filter-span'>Filters</span>
            <img alt='filter' height={18} src={filterBtn} width={18} />
          </StyledBadge>
        </TransparentButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus
        disableScrollLock={location !== '/objects'}
        onClose={handleClose}
        open={open}
        style={{ top: '10px' }}
        sx={{
          '& .MuiList-root': {
            paddingBottom: '60px',
            maxWidth: '90%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          '&& .MuiPaper-root': {
            width: '100%',
          },
          '&& .MuiMenuItem-root': {
            width: '33.3%',
            paddingBottom: '20px',
            paddingRight: '20px',
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FilterMenu
          clearFilter={clearFilter}
          completeOpts={completeOpts}
          filterFeats={filterFeats}
          filterOptions={filterOptions}
          formik={formik}
          handleClose={handleClose}
          loading={loading}
          setCompleteOpts={setCompleteOpts}
          setTyping={setTyping}
        />
      </Menu>
    </div>
  );
};

export default FilterButton;
