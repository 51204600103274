/* eslint-disable import/no-named-as-default */
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import filterObjectSlice from './filterObjectSlice'
import filterOfferSlice from './filterOfferSlice'
import filterLogSlice from './filterLogSlice'
import offersArray from './allOffers'
import objectsArray from './allObjects'
import logsArray from './allLogs'
import errorSlice from './errorSlice'
import isLogin from './isLogin'
import oneObject from './singleObject'
import favsArray from './allFavs'
import sortOfferSlice from './sortOffer'
import sortObjectSlice from './sortObject'
import filterFeatsObjects from './filterFeatsObjects'
import filterFeatsLogs from './filterFeatsLogs'
import filterFeatsOffers from './filterFeatsOffers'
import flagSlice from './loadFlag'

const rootReducer = combineReducers({
  filtersObject: filterObjectSlice,
  filtersOffer: filterOfferSlice,
  filtersLog: filterLogSlice,
  offers: offersArray,
  objects: objectsArray,
  logs: logsArray,
  favs: favsArray,
  error: errorSlice,
  logged: isLogin,
  singleObject: oneObject,
  sortOffer: sortOfferSlice,
  sortObject: sortObjectSlice,
  filterFeatsObject: filterFeatsObjects,
  filterFeatsLog: filterFeatsLogs,
  filterFeatsOffer: filterFeatsOffers,
  flag: flagSlice,
})

export default configureStore({
  reducer: rootReducer,
})
