import greenCircle from '../../images/greenCircle.svg';
import grayCircle from '../../images/grayCircle.svg';
import './StatusLabel.css';

function StatusLabel({ status, objectPage }) {
  const classes = objectPage ? 'status-label-objpage' : 'status-label';

  return (
    <div
      className={classes}
      style={{
        backgroundColor: status === 'ACTIVE' ? '#F6F6F4' : '#CCD0DB',
        color: status === 'ACTIVE' ? '#3d3d3d' : 'white',
      }}
    >
      <img alt={status} src={status === 'ACTIVE' ? greenCircle : grayCircle} />
      {status}
    </div>
  );
}

export default StatusLabel;
