import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiCallGet } from '../utils/functions';
import config from '../config';

const linkFilterObject = config.baseURL + config.api + config.estateObj + config.filters;
const linkFilterOffer = config.baseURL + config.api + config.estateOffer + config.filters;
const linkFilterLog = config.baseURL + config.api + config.logs + config.filters;

export const getFiltersLogs = createAsyncThunk('filterFeatsLog', async (filters, { rejectWithValue, dispatch }) => {
  const array = await apiCallGet(linkFilterLog, dispatch, rejectWithValue);
  return array;
});

export const getFiltersOffer = createAsyncThunk('filterFeatsOffer', async (filters, { rejectWithValue, dispatch }) => {
  const array = await apiCallGet(linkFilterOffer, dispatch, rejectWithValue);
  return array;
});

export const getFiltersObjects = createAsyncThunk(
  'filterFeatsObject',
  async (filters, { rejectWithValue, dispatch }) => {
    const array = await apiCallGet(linkFilterObject, dispatch, rejectWithValue);
    return array;
  }
);
