/* eslint-disable array-callback-return */
export const findVal = (filterUserSettings, property, range) => {
  if (filterUserSettings) {
    const res = filterUserSettings.find((el) => el.key === property)?.value[range];
    return res !== undefined ? res : '';
  }
  return '';
};

export const findSel = (filterUserSettings, property) => {
  if (filterUserSettings) {
    const res = filterUserSettings.find((el) => el.key === property)?.value;
    return res !== undefined ? res : '';
  }
  return '';
};

export const initialValues = (filterOptions, filterUserSettings, filterFeats) => {
  const result = {};
  if (filterOptions) {
    filterFeats.map((el) => {
      if (el.type === 'RANGE') {
        const newVal = {
          min: findVal(filterUserSettings, el.key, 'min'),
          max: findVal(filterUserSettings, el.key, 'max'),
        };
        result[el.key] = newVal;
      }
      if (el.type === 'MULTISELECT' || el.type === 'TEXT') {
        const newVal = findSel(filterUserSettings, el.key);
        result[el.key] = newVal;
      }
    }, []);
  }
  return result;
};
