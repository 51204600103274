/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const sortOfferSlice = createSlice({
  name: 'sortOffer',
  initialState: {},
  reducers: {
    addSortOffer: (state, action) => action.payload,
  },
});

export default sortOfferSlice.reducer;
export const { addSortOffer, removeSortOffer } = sortOfferSlice.actions;
