import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiCallPost } from '../utils/functions';
import config from '../config';

const linkGetHomes = config.baseURL + config.api + config.estateObj + config.search;

export const getFavs = createAsyncThunk('favs/addFavArray', async (filters, { rejectWithValue, dispatch }) => {
  const body = { favorites: true };
  const array = await apiCallPost(linkGetHomes, body, dispatch, rejectWithValue);
  return array;
});

export const getMoreRowsFavs = createAsyncThunk(
  'favs/addMoreFavArray',
  async (filters, { rejectWithValue, dispatch }) => {
    const body = { favorites: true };
    const array = await apiCallPost(linkGetHomes, body, dispatch, rejectWithValue);
    return array;
  }
);
