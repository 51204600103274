/* eslint-disable no-param-reassign */
import { addSortObject } from '../../../store/sortObject';
import IconToChoose from '../iconChangeSortBlack/IconChangeBlack';
import '../sortOptions/SortOptions.css';

function Popover({ item, label, selectedSorters, dispatch, dataKey }) {
  const changeSelect = (num) => {
    const a = { ...selectedSorters };
    const b = {};
    if (num) {
      b[dataKey] = num;
      dispatch(addSortObject(b));
    } else {
      const filtered = Object.keys(a).reduce((object, key) => {
        if (key !== dataKey) {
          object[key] = a[key];
        }
        return object;
      }, {});

      dispatch(addSortObject(filtered));
    }
  };

  return (
    <div className='label'>
      {label}
      {IconToChoose(changeSelect, item)}
    </div>
  );
}

export default Popover;
