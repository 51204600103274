import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import ImageGallery from 'react-image-gallery'

import X from '../../../images/x.svg'
import './FullScreenImageGallery.css'

const style = {
  position: 'absolute',
  top: '54%',
  left: '50%',
  height: '100%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: '#3d3d3d',
  boxShadow: 24,
  p: 4,
}

function CloseBtn({ handleClose }) {
  return (
    <button className='closeButton' onClick={handleClose} type='button'>
      <img alt='close' src={X} />
    </button>
  )
}

const imageHeight = window.innerHeight * 0.75

export default function GalleryModal({ open, handleClose, imagesData, imageIndex }) {
  const imagesArray = imagesData
    ? imagesData?.map((item) => ({
        original: item,
        thumbnail: item,
        originalClass: 'ImageModal',
        thumbnailClass: 'ThumbModal',
        originalHeight: imageHeight,
      }))
    : ''

  return (
    <Modal
      aria-describedby='modal-modal-description'
      aria-labelledby='modal-modal-title'
      onClose={handleClose}
      open={open}
    >
      <Box sx={style}>
        <CloseBtn handleClose={handleClose} />
        {imagesArray && <ImageGallery items={imagesArray} showFullscreenButton={false} startIndex={imageIndex} />}
      </Box>
    </Modal>
  )
}
