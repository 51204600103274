import { useEffect, useState } from 'react';
import { Grid, Tabs, Tab, AppBar } from '@mui/material';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from 'victory';
import db from '../../../images/dbBl.svg';
import dbTwo from '../../../images/dbBlack.svg';
import heartG from '../../../images/heartG.svg';

import exalLogo from '../../../images/exalLogo.svg';
import { setIsLogin } from '../../../store/isLogin';
import { setTabNum } from '../../../store/allObjects';
import FilterButton from '../../Filters/filterDropdown';
import './TopHeader.css';

const TopHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabNum = useSelector((state) => state?.objects?.tabNum);

  const logout = () => {
    localStorage.removeItem('mls');
    dispatch(setIsLogin(false));
    navigate('/login');
  };
  const [pageName, setPageName] = useState('');

  const settingsLink = '/settings';
  const rootLink = '/';
  const journalLink = '/journal';
  const dataLink = '/data';

  const tabStyle = {
    fontFamily: 'Montserrat',
    color: '#000 !important',
    minHeight: 60,
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: location.pathname === dataLink ? 'rgba(126, 124, 207,0.1)' : 'transparent',
    },
  };

  const value = useSelector((state) => state?.objects?.tabNum);

  const handleChange = (event, newValue) => {
    dispatch(setTabNum(newValue));
  };

  useEffect(() => {
    switch (true) {
      case location.pathname === dataLink && tabNum === 0:
        setPageName('Property listings');
        break;
      case location.pathname === dataLink && tabNum === 1:
        setPageName('Agents listings');
        break;
      case location.pathname === dataLink && tabNum === 2:
        setPageName('Favorites');
        break;
      case location.pathname === settingsLink:
        setPageName('Settings');
        break;
      default:
        setPageName('Main page');
    }
  }, [location, tabNum]);

  return (
    <AppBar className='appBar' position='fixed'>
      <Toolbar className='sideMenu' disableGutters>
        <Grid container>
          <Grid item md={2} sx={{ '@media screen and (max-width: 600px)': { display: 'none' } }}>
            <div className='logo-cont'>
              <Link to={rootLink}>
                <button className='transparent-button ' type='button'>
                  <img alt='logo' height={32} src={exalLogo} width={149} />
                </button>
              </Link>
            </div>
          </Grid>
          <Grid item md={6} sx={{ '@media screen and (max-width: 600px)': { display: 'none' } }}>
            <Grid container justify='center'>
              <Link to={dataLink}>
                <Tabs
                  allowScrollButtonsMobile
                  aria-label='nav-pages'
                  onChange={handleChange}
                  scrollButtons
                  sx={{
                    '& .MuiTabs-scrollButtons.Mui-disabled': {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                  TabIndicatorProps={{
                    sx: {
                      height: 4,
                      backgroundColor: location.pathname === dataLink ? '#333333' : 'transparent',
                    },
                  }}
                  value={value}
                >
                  <Tab
                    icon={<img alt='db' src={db} sx={{ paddingRight: 5 }} />}
                    iconPosition='start'
                    label='Property listings'
                    sx={tabStyle}
                  />
                  <Tab
                    icon={<img alt='offers' src={dbTwo} sx={{ paddingRight: 5 }} />}
                    iconPosition='start'
                    label='Agents’ listing'
                    sx={tabStyle}
                  />
                  <Tab
                    icon={<img alt='favs' src={heartG} sx={{ paddingRight: 5 }} />}
                    iconPosition='start'
                    label='Favorites'
                    sx={tabStyle}
                  />
                </Tabs>
              </Link>
            </Grid>
          </Grid>
          <Grid item md={3} sx={{ '@media screen and (max-width: 600px)': { display: 'none' } }}>
            <div className='small-btn-cont'>
              <Link to={settingsLink}>
                <button
                  className='roundIconButton'
                  id={location.pathname === settingsLink ? 'iconGearBlack' : 'iconGear'}
                  type='button'
                />
              </Link>
              <Link to={journalLink}>
                <button
                  className='roundIconButton'
                  id={location.pathname === journalLink ? 'iconJournalBlack' : 'iconJournal'}
                  type='button'
                />
              </Link>
              <button className='roundIconButton' id='exit' onClick={logout} type='button' />
            </div>
          </Grid>
          <Grid item md={1} sx={{ pt: '9px' }} xs={12}>
            <div className='btn-with-name'>
              <div className='page-heading'>{pageName}</div>
              {(location.pathname === dataLink || location.pathname === journalLink) && <FilterButton />}
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopHeader;
