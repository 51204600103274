/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getFiltersLogs } from '../api/getFilters';

export const filterFeatsLogs = createSlice({
  name: 'filterFeatsLog',
  initialState: { feats: [], status: null, error: null },
  extraReducers: {
    [getFiltersLogs.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [getFiltersLogs.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.feats = action.payload;
    },
    [getFiltersLogs.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
  },
});

export default filterFeatsLogs.reducer;
