import React from 'react';

import Table from '../components/Tables/tableOffers/Table';
import '../styles/MainPage.css';

function MainPage() {
  return <Table />;
}

export default MainPage;
