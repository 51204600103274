const config = {
  baseURL: process.env.REACT_APP_BASEURL,
  api: '/api',
  search: '/search',
  estateObj: '/estate-object',
  estateOffer: '/estate-offer',
  filters: '/filters',
  docs: '/docs#',
  users: '/Users',
  login: '/login',
  user: '/user',
  storage: '/storage',
  logs: '/parsing-log',
  bulk: '/bulk',
  fav: '/favorite',
  autocomplete: '/autocomplete',
  searchFilters: '/search-filters',
};

export default config;
