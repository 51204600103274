import { tableLogsWidth } from '../../../utils/constants';
import './CellStyles.css';

function HeaderRenderer() {
  return (
    <div className='logHeader' style={{ backgroundColor: '#3d3d3d', width: tableLogsWidth }}>
      <div style={{ paddingLeft: '20px' }}>Status</div>
      <div>Date</div>
      <div>Source</div>
      <div style={{ paddingLeft: '15px' }}>Message</div>
      <div>Source ID</div>
    </div>
  );
}

export default HeaderRenderer;
