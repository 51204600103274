/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Skeleton } from '@mui/material';

import { setScrollY } from '../../../store/allOffers';
import ModalImageGallery from '../../Modals/modalImageGallery/ModalImageGallery';
import { tableWidth } from '../../../utils/constants';
import { getTable } from '../../../api/getTable';
import ShowTotals from '../../showTotals/ShowTotals';
import HeaderRenderer from '../tableOfferComponents/HeaderRenderer';
import RowRenderer from '../tableOfferComponents/RowRenderer';
import bed from '../../../images/bed1.svg';
import bath from '../../../images/bath.svg';
import './Table.css';

export default function ReactVirtualizedTable() {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filtersObject);
  const sort = useSelector((state) => state.sortObject);
  const offerObject = useSelector((state) => state?.offers?.offers);
  const isLoading = useSelector((state) => state?.offers?.isLoading);
  const rowsData = useSelector((state) => state?.offers?.rows);
  const flag = useSelector((state) => state?.flag);
  const data = rowsData;
  const totalQty = offerObject?.total || 0;
  const grandTotalQty = offerObject?.grandTotal;
  const scrollPosition = useSelector((state) => state?.offers?.scrollY);

  const [imagesData, setImagesData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
    setSelectedIndex(-1);
  };

  const handleOpenModal = (cellData) => {
    setImagesData(cellData);
    handleOpen();
  };

  const rows =
    data && data.length > 0
      ? data.map((item) => ({
          ...item,
          obj: { type: item.type, offerType: item.offerType, specifiedStatus: item.specifiedStatus },
          sourceName: { name: item.sourceName, link: item.url },
          attributes: [
            { val: item.bedroomsCount, label: 'Beds', imgSrc: bed },
            { val: item.bathroomsCount, label: 'Baths', imgSrc: bath },
            { val: item.roomsCount, label: 'Rooms:' },
            { val: item.square, label: 'Usable square:', meters: true },
            { val: item.livingSquare, label: 'Living square:', meters: true },
            { val: item.plotSquare, label: 'Land area:', meters: true },
            { val: item.floor, label: 'Floor:' },
            { val: item.floors, label: 'Floors:' },
          ],
          image: {
            images: item?.images,
            originalImages: item?.originalImages,
            specifiedName: item.specifiedName,
            id: item.estateObjectId,
            offerId: item._id,
          },
          agent: item.agent.name,
          agentAddress: item.agent.address,
          phone: item.agent.phone,
          coords: `${Number(item.latitude)?.toFixed(5)}, ${Number(item.longitude)?.toFixed(5)}`,
          price: {
            price: item.price?.toLocaleString() || '',
            currency: item.currency || '',
            priceInterval: item.priceInterval || undefined,
          },
          createdAt: new Date(item.createdAt).toLocaleDateString(),
          isFav: {
            isFavorite: item.isFavorite,
            connectedId: item?.relatedOffersIds,
            id: item._id,
            estateObjectId: item?.estateObjectId,
          },
        }))
      : [];
  const tableHeight = window.innerHeight - 150;

  const RowRender = ({ index, style }) => (
    <RowRenderer
      {...{
        index,
        style,
        rows,
        expanded,
        handleExpandClick,
        handleClose,
        selectedIndex,
        setSelectedIndex,
        handleOpenModal,
        tableWidth,
      }}
    />
  );

  const loadMoreItems = (startIndex, stopIndex) => {
    dispatch(getTable({ filters, sort, offset: startIndex, limit: stopIndex - startIndex }));
  };

  const hasNextPage = true;

  const isItemLoaded = (index) => !hasNextPage || index < rows.length;

  return (
    <div className='wrapper'>
      <div>
        <ShowTotals flag={flag} grandTotalQty={grandTotalQty} loading={isLoading} page='Offers' totalQty={totalQty} />
      </div>
      <div className='table-wrapper'>
        {rows?.length > 0 && <HeaderRenderer />}
        <div style={{ minHeight: tableHeight, width: tableWidth }}>
          {rows.length > 0 && (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={totalQty}
              loadMoreItems={loadMoreItems}
              minimumBatchSize={30}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  ref={ref}
                  height={tableHeight}
                  initialScrollOffset={scrollPosition}
                  itemCount={totalQty}
                  itemData={rows}
                  itemSize={195}
                  onItemsRendered={onItemsRendered}
                  onScroll={(e) => dispatch(setScrollY(e.scrollOffset))}
                  overscanCount={20}
                  width={tableWidth}
                >
                  {RowRender}
                </List>
              )}
            </InfiniteLoader>
          )}
          {isLoading && (
            <>
              <Skeleton />
              <Skeleton />
            </>
          )}
        </div>
      </div>
      <ModalImageGallery handleClose={handleClose} imagesData={imagesData} open={open} />
    </div>
  );
}
