import { createSlice } from '@reduxjs/toolkit';

export const filterObjectSlice = createSlice({
  name: 'filterObject',
  initialState: [],
  reducers: {
    addFilterObject: (state, action) => action.payload,
    removeFilterObject: () => [],
  },
});

export default filterObjectSlice.reducer;
export const { addFilterObject, removeFilterObject } = filterObjectSlice.actions;
