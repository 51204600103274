/* eslint-disable no-unsafe-optional-chaining */
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Tab, Breadcrumbs, Stack, Link, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { showPriceRange } from '../utils/functions';
import { removeObject } from '../store/singleObject';
import getSingleObject from '../api/getSingleObject';
import config from '../config';
import ImagesSwiper from '../components/imagesSwiper/ImagesSwiper';
import LinedData from '../components/SingleObject/linedData/LinedData';
import '../styles/SingleObjectPage.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const theme = createTheme({
  palette: {
    active: {
      main: '#00BC00',
    },
    removed: {
      main: '#8B8B8B',
    },
  },
});

const baseURL = config.baseURL + config.storage;

function SingleObjectPage() {
  const dispatch = useDispatch();
  const [imageIndex, setImageIndex] = React.useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [priceOverall, setPriceOverall] = React.useState('');
  const [query, setQuery] = React.useState(searchParams.get('offerId'));
  const [galleryIndex, setGalleryIndex] = React.useState({});
  const [chosen, setChosen] = React.useState('');
  const flag = useSelector((state) => state?.flag);

  const params = useParams();

  const data = useSelector((state) => state?.singleObject?.oneObject[0]);

  // images array for Summary Tab
  const imagesQty = data?.offers
    ?.flatMap((el) => el.images)
    .filter((el) => el)
    .map((el) => `${baseURL}${el}`);
  const originalImages = data?.offers?.flatMap((el) => el.originalImages)?.filter((el) => !el.includes('userfiles'));
  const imagesOverall = imagesQty?.length > 0 ? imagesQty : originalImages;

  const [value, setValue] = React.useState(0);
  const [imagesData, setImagesData] = React.useState(imagesOverall);

  const startVal = data?.offers?.findIndex((el) => el?._id === query) + 1;

  // images array for exact Tab
  const findImage = (val) => data?.offers[val - 1]?.images?.map((el) => `${baseURL}${el}`);
  const findImageOriginal = (val) => data?.offers[val - 1]?.originalImages?.filter((el) => !el.includes('userfiles'));

  // make page open on exact tab if it appears in the link and load images on opening
  React.useEffect(() => {
    if (startVal) {
      setValue(startVal);
      setChosen(`${data?.offers[startVal]?._id}`);
      setImagesData(findImage(startVal)?.length > 0 ? findImage(startVal) : findImageOriginal(startVal));
    }
    if (!startVal) {
      setChosen('overall');
      if (!value) {
        setImagesData(imagesOverall);
      }
    }
  }, [startVal]);

  React.useEffect(() => {
    dispatch(removeObject());
    if (flag) {
      dispatch(getSingleObject(params));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== 0) {
      const offerId = data?.offers[newValue - 1]?._id;
      setSearchParams({ offerId });
      setQuery({ offerId });
      setChosen(data?.offers[newValue - 1]._id);
      setImageIndex(0);
      if (data?.offers) {
        setImagesData(findImage(newValue)?.length > 0 ? findImage(newValue) : findImageOriginal(newValue));
      }
    } else {
      setSearchParams('');
      setQuery('');
      setChosen('overall');
      setImageIndex(0);
      if (data?.offers) {
        setImagesData(imagesOverall);
      }
    }
  };

  const offersWithOverall = {
    ...data,
    images: imagesOverall || originalImages,
    status: data?.offers[0]?.status,
  };

  const currency = data?.offers?.find((el) => el.currency)?.currency;

  React.useEffect(() => {
    showPriceRange(data, setPriceOverall, currency);
  }, [data, currency]);

  const offStatus = data?.offers[value - 1]?.status || data?.status;

  return (
    <ThemeProvider theme={theme}>
      <div className='objectPage-wrapper'>
        <Stack sx={{ pb: 3 }}>
          <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon sx={{ fontSize: 14 }} />}>
            <Link
              key='2'
              color='primary'
              href='/data'
              sx={{ fontSize: '14px !important', color: ' #CCD0DB', fontWeight: 500, opacity: 0.7 }}
              underline='hover'
            >
              Property listings
            </Link>
            <Typography sx={{ fontSize: '14px !important', color: '#3d3d3d', fontWeight: 600, opacity: 0.7 }}>
              {data?.name || data?.specifiedName}
            </Typography>
          </Breadcrumbs>
        </Stack>
        {imagesData?.length > 0 ? (
          <div style={{ height: '580px' }}>
            <ImagesSwiper
              chosen={chosen}
              data={imagesData}
              galleryIndex={galleryIndex}
              imageIndex={imageIndex}
              offStatus={offStatus}
              setGalleryIndex={setGalleryIndex}
              setImageIndex={setImageIndex}
            />
          </div>
        ) : (
          <div className='no-image-swiper'>No image</div>
        )}
        <Tabs
          aria-label='basic tabs example'
          className='tabs-wrap'
          onChange={handleChange}
          scrollButtons
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
            '&& .MuiTabs-flexContainer': {
              alignItems: 'center',
            },
            '&& .MuiTab-root': {
              minHeight: 38,
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: 10,
            },
            '&& .Mui-selected': {
              backgroundColor: 'rgba(126, 124, 207, 0.1)',
              color: '#7E7CCF',
            },
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#7E7CCF',
            },
          }}
          value={value}
          variant='scrollable'
        >
          <Tab key={0} className='tab-label-summary' label='Summary' />
          {data?.offers?.map((el) => (
            <Tab
              key={el._id}
              className='tab-label'
              icon={
                <Brightness1Icon
                  color={el.status === 'ACTIVE' ? 'active' : 'removed'}
                  sx={{
                    '&.MuiTab-iconWrapper': { marginRight: '5px', marginBottom: '2px' },
                    fontSize: '9px !important',
                  }}
                />
              }
              iconPosition='start'
              label={el.agent?.name || 'no name'}
            />
          ))}
        </Tabs>
        <TabPanel key={0} className='tab-panel' index={0} value={value}>
          <LinedData data={offersWithOverall} imagesData={imagesData} overall priceOverall={priceOverall} />
        </TabPanel>
        {data?.offers?.map((el, index) => (
          <TabPanel key={el._id} className='tab-panel' index={index + 1} value={value}>
            <LinedData data={el} imageIndex={imageIndex} imagesData={imagesData} />
          </TabPanel>
        ))}
      </div>
    </ThemeProvider>
  );
}

export default SingleObjectPage;
