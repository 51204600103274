/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { VictoryPie } from 'victory-pie';
import { VictoryLabel } from 'victory';
import { Grid } from '@mui/material';

import './PieChart.css';

const PieChart = ({ title, colorArray, myData, nameData }) => {
  const percentActive = ((myData?.[0]?.y / (myData?.[0]?.y + myData?.[1]?.y)) * 100).toFixed(0);

  return (
    <div className='chartWrap'>
      <div className='titleChart'>{title}</div>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <svg height={200} width={200}>
            <VictoryPie
              colorScale={colorArray}
              data={myData}
              height={200}
              innerRadius={70}
              labels={() => null}
              radius={100}
              standalone={false}
              width={200}
            />
            <VictoryLabel
              style={{ fontSize: 40, fill: '#7E7CCF', fontFamily: 'Montserrat', fontWeight: 600 }}
              text={`${percentActive}%`}
              textAnchor='middle'
              x={100}
              y={100}
            />
          </svg>
        </Grid>
        <Grid item sm={6} sx={{ pl: 3 }} xs={12}>
          <div className='legendWrap'>
            {nameData.map((el) => (
              <div key={el.title}>
                <div className='dotTitle'>{el.title}</div>
                <div className='dotWithLegend'>
                  <div className='colorDot' style={{ backgroundColor: el.dotColor }} />
                  <div className='dotQty'>
                    {el.value}&nbsp;
                    <span className='dotTitle'>{el.units}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PieChart;
