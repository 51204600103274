/* eslint-disable react/no-array-index-key */
import { TableCell, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';

import GreenGreyButton from '../../greenGreyButton/GreenGreyButton';

const truncValue = 60;

export function ShortCell({ cellData }) {
  return (
    <TableCell className='common-cell' component='div' sx={{ border: 'none', pt: '10px' }}>
      {cellData ? <Typography>{cellData}</Typography> : <Typography>–</Typography>}
    </TableCell>
  );
}

export function LongCell({ cellData }) {
  const cellDataTruncated = `${cellData?.slice(0, truncValue)}...`;

  return (
    <TableCell
      className='common-cell'
      component='div'
      sx={{ border: 'none', cursor: 'pointer', pr: '12px', pt: '10px' }}
      variant='body'
    >
      <PopupState popupId='demoPopover' variant='popover'>
        {(popupState) => (
          <div>
            <Typography
              sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column' }}
              {...bindHover(popupState)}
            >
              {cellDataTruncated}
            </Typography>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              disableScrollLock
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography style={{ width: '300px', padding: '30px' }}>{cellData}</Typography>
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </TableCell>
  );
}

export function CommonCell({ cellData }) {
  if (cellData?.length > truncValue) {
    return <LongCell cellData={cellData} truncValue={truncValue} />;
  }
  return <ShortCell cellData={cellData} />;
}

export function ButtonCell({ cellData }) {
  return (
    <TableCell className='common-cell' component='div' sx={{ border: 'none', pt: '10px' }}>
      {cellData?.name ? (
        <Typography sx={{ fontWeight: 600, color: '#333333' }}>{cellData?.name}</Typography>
      ) : (
        <Typography>–</Typography>
      )}
      <a href={cellData?.link} rel='noopener noreferrer' target='_blank'>
        <button className='more-button' type='button'>
          View ad
        </button>
      </a>
    </TableCell>
  );
}

export function LinkCell({ cellData }) {
  return (
    <TableCell
      component='div'
      style={{
        border: 'none',
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <a className='link' href={cellData} rel='noopener noreferrer' target='_blank'>
        <Typography style={{ textDecoration: 'underline' }}>View ad</Typography>
      </a>
    </TableCell>
  );
}

export function ChipCell({ cellData }) {
  return (
    <TableCell className='common-cell' component='div' sx={{ border: 'none', pt: '10px' }}>
      <GreenGreyButton
        color={cellData?.offerType?.toLowerCase() === 'rent' ? 'green' : 'grey'}
        status={cellData?.offerType}
      />
      <Typography sx={{ textTransform: 'capitalize', fontWeight: 600, marginTop: '10px', color: '#333333' }}>
        {cellData?.type?.toLowerCase()}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize', fontWeight: 400, marginTop: '10px', color: 'blue' }}>
        {cellData?.specifiedStatus}
      </Typography>
    </TableCell>
  );
}

export function PriceCell({ cellData }) {
  const PriceToShow = () => {
    if ((!cellData?.price && cellData?.currency) || (!cellData?.price && !cellData?.currency)) {
      return 'on request';
    }
    if (cellData.price && !cellData?.currency) {
      if (cellData?.priceInterval) return `${cellData?.price} / ${cellData?.priceInterval}`;
      return cellData?.price;
    }
    if (cellData?.price && cellData?.currency) {
      if (cellData?.priceInterval) return `${cellData?.price} ${cellData?.currency} / ${cellData?.priceInterval}`;
      return `${cellData?.price} ${cellData?.currency}`;
    }
  };

  return (
    <TableCell className='common-cell' component='div' sx={{ margin: '5px', border: 'none' }}>
      <Typography sx={{ fontWeight: 500, color: '#3d3d3d' }}>{PriceToShow()}</Typography>
    </TableCell>
  );
}

export const TitleCell = ({ cellData }) => {
  const trunkVal = 25;

  const titleToShow = () => {
    switch (true) {
      case cellData?.specifiedName && cellData?.specifiedName?.length > trunkVal:
        return `${cellData?.specifiedName?.slice(0, trunkVal)}...`;
      case cellData?.specifiedName && cellData?.specifiedName?.length < trunkVal:
        return cellData?.specifiedName;
      default:
        return '–';
    }
  };

  return (
    <Link to={`/objects/${cellData?.id}?offerId=${cellData?.offerId}`}>
      <TableCell
        component='div'
        role='button'
        sx={{ cursor: 'pointer', border: 'none', padding: 0, pb: 1 }}
        variant='body'
      >
        <PopupState popupId='demoPopover' variant='popover'>
          {(popupState) => (
            <div className='title-cell'>
              <div className='title-one-cell' {...bindHover(popupState)}>
                <Typography sx={{ fontWeight: 600, color: '#333333' }}>{titleToShow()}</Typography>
                <button className='more-button' type='button'>
                  More...
                </button>
              </div>
              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                disableScrollLock
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <Typography style={{ width: '200px', padding: '30px' }}>{cellData?.specifiedName}</Typography>
              </HoverPopover>
            </div>
          )}
        </PopupState>
      </TableCell>
    </Link>
  );
};

export const AttributesCell = ({ cellData }) => (
  <TableCell className='attr-cell' component='div' sx={{ paddingTop: '12px' }} variant='body'>
    {cellData?.map((el) => (
      <div key={el.label} className='attr-item'>
        {el.imgSrc ? (
          <>
            <img alt={el.label} src={el.imgSrc} style={{ height: '18px', width: '18px', paddingBottom: 6 }} />
            <span>&nbsp;&nbsp;</span>
            <Typography>
              {el.val || '–'}&nbsp;&nbsp;{el.label}
            </Typography>
          </>
        ) : (
          <Typography>
            {el.label}&nbsp;&nbsp;{el.val || '–'}&nbsp;{' '}
            {el.meters && el.val > 0 && (
              <span>
                m<sup>2</sup>
              </span>
            )}
          </Typography>
        )}
      </div>
    ))}
  </TableCell>
);

export function FeatureCell({ cellData }) {
  const linesToShow = 8;

  return (
    <TableCell component='div' sx={{ paddingTop: '12px', border: 'none', cursor: 'pointer' }} variant='body'>
      <PopupState popupId='demoPopover' variant='popover'>
        {(popupState) => (
          <div role='button' style={{ textTransform: 'capitalize' }}>
            {cellData && cellData[0] ? (
              <ul {...bindHover(popupState)} style={{ paddingLeft: 0, marginTop: 0 }}>
                {cellData
                  ?.toString()
                  ?.toLowerCase()
                  ?.split(',')
                  ?.slice(0, linesToShow)
                  ?.map((item, index) => (
                    <li key={index}>
                      <Typography>{item} </Typography>
                    </li>
                  ))}
                {cellData && cellData?.toString()?.split(',').length > linesToShow && <span>...</span>}
              </ul>
            ) : (
              <Typography>–</Typography>
            )}
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              disableScrollLock
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {cellData ? (
                <ul style={{ paddingLeft: '30px', paddingRight: '30px', textTransform: 'capitalize' }}>
                  {cellData
                    ?.toString()
                    ?.toLowerCase()
                    ?.split(',')
                    ?.map((item, index) => (
                      <li key={index}>
                        <Typography>{item} </Typography>
                      </li>
                    ))}
                </ul>
              ) : (
                'no data'
              )}
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </TableCell>
  );
}

export function DescCell({ cellData }) {
  const cellDataTruncated = `${cellData?.slice(0, 380)}...`;

  function createMarkup(str) {
    return { __html: str };
  }

  return (
    <TableCell
      className='title-cell'
      component='div'
      sx={{ paddingTop: '12px', border: 'none', cursor: 'pointer' }}
      variant='body'
    >
      <PopupState popupId='demoPopover' variant='popover'>
        {(popupState) => (
          <>
            <Typography
              className='desc-cell'
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 9,
                padding: 0,
              }}
              {...bindHover(popupState)}
              dangerouslySetInnerHTML={createMarkup(cellDataTruncated)}
            />
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              disableScrollLock
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography
                dangerouslySetInnerHTML={createMarkup(cellData)}
                style={{ width: '500px', padding: '30px' }}
              />
            </HoverPopover>
          </>
        )}
      </PopupState>
    </TableCell>
  );
}

export const StatusCell = ({ cellData }) => (
  <TableCell className='common-cell' component='div' sx={{ border: 'none', paddingTop: '12px' }}>
    {cellData ? (
      <Typography>
        <GreenGreyButton color={cellData?.toLowerCase() === 'active' ? 'green' : 'grey'} red status={cellData} />
      </Typography>
    ) : (
      <Typography>–</Typography>
    )}
  </TableCell>
);
