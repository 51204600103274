/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getFiltersObjects } from '../api/getFilters';

export const filterFeatsObjects = createSlice({
  name: 'filterFeatsObjects',
  initialState: { feats: [], status: null, error: null },
  extraReducers: {
    [getFiltersObjects.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [getFiltersObjects.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.feats = action.payload;
    },
    [getFiltersObjects.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
  },
});

export default filterFeatsObjects.reducer;
