const errorHandler = (status) => {
  switch (true) {
    case status.toString().includes('403'):
      return 'Access Denied'
    case status.toString().includes('401'):
      return 'Please log in to continue'
    case status.toString().includes('404'):
      return 'There is no user with these credentials'
    default:
      return 'Something went wrong, please try again later'
  }
}

export default errorHandler
