/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Thumbs, Keyboard } from 'swiper/core';
import { Grid } from '@mui/material';

import './ImagesSwiper.css';
import StatusLabel from '../statusLable/StatusLabel';

SwiperCore.use([Pagination, Navigation, Thumbs, Keyboard]);

function ImagesSwiper({ data, setImageIndex, offStatus }) {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const imgsArrCopy = data?.slice();
  imgsArrCopy?.shift();
  imgsArrCopy?.push(data[0]);

  return (
    <>
      {data && data.length > 0 && (
        <Grid container spacing={2}>
          <Grid className='ImageContainer' item xs={8}>
            <Swiper
              initialSlide={0}
              keyboard={{
                enabled: true,
                onlyInViewport: false,
              }}
              loop
              navigation
              onSlideChange={(swiperCore) => {
                const { activeIndex } = swiperCore;
                setImageIndex(activeIndex);
              }}
              slidesPerView={1}
              spaceBetween={5}
              thumbs={{ swiper: thumbsSwiper }}
            >
              {data?.map((item, index) => (
                <div key={index}>
                  <SwiperSlide key={index}>
                    <img alt='house' className='Image' src={item} />
                  </SwiperSlide>
                </div>
              ))}
              <StatusLabel status={offStatus} />
            </Swiper>
          </Grid>
          <Grid item xs={4}>
            <Swiper
              className='Swiper'
              direction='vertical'
              onSwiper={setThumbsSwiper}
              slidesPerView={2}
              slideToClickedSlide
              spaceBetween={10}
              watchSlidesProgress
            >
              {imgsArrCopy?.map((item, index) => (
                <div key={index}>
                  <SwiperSlide key={index}>
                    <img alt='house2' className='Thumb' src={item} />
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ImagesSwiper;
