import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

import './CellStyles.css';

function DotColor({ cellData }) {
  switch (true) {
    case cellData.includes('FAIL'):
      return (
        <>
          <CancelIcon sx={{ color: '#f44336' }} />
          <Typography sx={{ color: '#f44336', paddingLeft: '7px', paddingTop: '1px' }}>{cellData}</Typography>
        </>
      );
    case cellData.includes('SUCCESS'):
      return (
        <>
          <CheckCircleIcon sx={{ color: '#4caf50' }} />
          <Typography sx={{ color: '#4caf50', paddingLeft: '7px', paddingTop: '1px' }}>{cellData}</Typography>
        </>
      );
    case cellData.includes('COMPLETE'):
      return (
        <>
          <CheckCircleIcon sx={{ color: '#8bc34a' }} />
          <Typography sx={{ color: '#8bc34a', paddingLeft: '7px', paddingTop: '1px' }}>{cellData}</Typography>
        </>
      );
    case cellData.includes('STARTED'):
      return (
        <>
          <PendingIcon sx={{ color: '#009688' }} />
          <Typography sx={{ color: '#009688', paddingLeft: '7px', paddingTop: '1px' }}>{cellData}</Typography>
        </>
      );
    default:
      return <Typography sx={{ color: 'transparent', paddingLeft: '7px', paddingTop: '1px' }}>{cellData}</Typography>;
  }
}

function StatusCell({ cellData }) {
  return (
    <TableCell className='common-cell' component='div' style={{ margin: '10px', border: 'none' }}>
      {cellData ? (
        <div className='icon-text'>
          <DotColor cellData={cellData} />
        </div>
      ) : (
        <Typography>–</Typography>
      )}
    </TableCell>
  );
}

export default StatusCell;
