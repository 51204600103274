import React from 'react';

import favList from '../images/favList.svg';
import agList from '../images/agList.svg';
import propList from '../images/propList.svg';
import '../styles/MainPage.css';
import PicTile from '../components/mainPageComponents/picTile/PicTile';
import PieChart from '../components/mainPageComponents/pieChart/PieChart';
// import MapStats from '../components/mainPageComponents/mapStats/MapStats';
// import { mapsData } from '../utils/citiesCenterCoords';

const MainPage = () => {
  const colorArray = ['#7E7CCF', '#D3DCFC'];
  const myData = [
    { x: 'Active ads', y: 603 },
    { x: '', y: 74 },
  ];

  const nameData = [
    { title: 'All ads', value: '677', dotColor: '#D3DCFC', units: 'ads' },
    { title: 'Active ads', value: '603', dotColor: '#7E7CCF', units: 'ads' },
  ];

  return (
    <div className='main'>
      <div className='emptySpace' />
      <div className='pictile-container'>
        <PicTile alt='fav' height={82} src={propList} text='Description' title='Property listings' val={0} width={82} />
        <PicTile alt='fav' height={82} src={agList} text='Description' title='Agents` listings' val={1} width={82} />
        <PicTile alt='fav' favs height={82} src={favList} text='Description' title='Favorites' val={2} width={82} />
      </div>
      <div className='chart-container'>
        <PieChart title='Ad statistics' {...{ nameData, myData, colorArray }} />
        <PieChart title='Ad statistics' {...{ nameData, myData, colorArray }} />
        <PieChart title='Ad statistics' {...{ nameData, myData, colorArray }} />
        <PieChart title='Ad statistics' {...{ nameData, myData, colorArray }} />
      </div>
      {/* <div className='titleChart' style={{ paddingBottom: 10, fontSize: 26 }}>
        Statistics of active properties by country
      </div>
      <div className='chart-container' style={{ marginTop: 20 }}>
        {mapsData.map((el) => (
          <MapStats key={el.id} item={el} />
        ))}
        </div> */}
      <div style={{ height: 100 }} />
    </div>
  );
};

export default MainPage;
