import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import theme from './styles/theme';
import Layout from './layout/Layout';
import { setIsLogin } from './store/isLogin';
import { removeError } from './store/errorSlice';
import MainPage from './pages/MainPage';
import Journal from './pages/Journal';
import Review from './pages/Review';
import Settings from './pages/Settings';
import Login from './pages/Login';
import UnitedPage from './pages/UnitedPage';
import SingleObjectPage from './pages/SingleObjectPage';
import './App.css';

function RequireAuth({ logged }) {
  const location = useLocation();
  if (!logged) {
    return <Navigate state={{ from: location }} to='/login' />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

function App() {
  const dispatch = useDispatch();
  const user = localStorage.getItem('mls');
  if (user) {
    dispatch(setIsLogin(true));
  }
  const error = useSelector((state) => state.error);
  const logged = useSelector((state) => state.logged);

  if (error) {
    if (error.status == 401) {
      toast.warn(error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        theme: 'colored',
        toastId: 'error1',
      });
    }
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      theme: 'colored',
      toastId: 'error1',
    });
    dispatch(removeError());
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Router>
          <Routes>
            <Route element={<Login />} end path='/login' />
            <Route element={<RequireAuth logged={logged} />}>
              <Route element={<MainPage />} end path='/' />
              <Route element={<Journal />} end path='/journal' />
              <Route element={<UnitedPage />} end path='/data' />
              <Route path='/objects'>
                <Route element={<SingleObjectPage />} path=':objectId' />
              </Route>
              <Route element={<Review />} end path='/review' />
              <Route element={<Settings />} end path='/settings' />
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
