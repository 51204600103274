import config from '../config';
import { apiCallNoStoreGet } from '../utils/functions';

const linkGetHomes = config.baseURL + config.api + config.fav;

const toggleFavs = async (id) => {
  const array = await apiCallNoStoreGet(`${linkGetHomes}/${id}`);
  return array;
};

export default toggleFavs;
