import { createAsyncThunk } from '@reduxjs/toolkit'

import config from '../config'
import { apiCallPost } from '../utils/functions'

const linkGetLogs = config.baseURL + config.api + config.logs

export const getLogs = createAsyncThunk('logs/addLogArray', async (body, { rejectWithValue, dispatch }) => {
  const array = await apiCallPost(linkGetLogs, body, dispatch, rejectWithValue)
  return array
})

export const getMoreRowsLogs = createAsyncThunk('logs/addMoreLogArray', async (body, { rejectWithValue, dispatch }) => {
  const array = await apiCallPost(linkGetLogs, body, dispatch, rejectWithValue)
  return array
})
