import { tableWidth } from '../../../utils/constants';
import heart from '../../../images/heartW.svg';
import './CellStyles.css';

const HeaderRenderer = () => (
  <div className='tableHeader' style={{ width: tableWidth }}>
    <div className='heartContainer'>
      <img alt='favs' height={25} src={heart} width={25} />
    </div>
    <div>Type</div>
    <div>Source</div>
    <div style={{ paddingLeft: 0 }}>Image</div>
    <div>Price</div>
    <div>Attributes</div>
    <div>Features</div>
    <div>Description</div>
    <div>Building year</div>
    <div>Renovation year</div>
    <div>Address</div>
    <div>City</div>
    <div>Country</div>
    <div>Reference</div>
    <div>Geo Coordinates</div>
    <div>Created</div>
    <div>Agent</div>
    <div>Agent Address</div>
    <div>Agent Phone</div>
    <div>Status</div>
    <div>Availability</div>
  </div>
);

export default HeaderRenderer;
