/* eslint-disable react/no-danger */
import * as React from 'react';
import { Grid } from '@mui/material';

import MapContainer from '../map/Map';
import AgentListItem from '../../agentListItem/AgentListItem';
import marker from '../../../images/markerBlue.svg';
import ref from '../../../images/ref.svg';
import hyperlink from '../../../images/hyperlink.svg';
import phone from '../../../images/phone.svg';
import './DescListed.css';

const DataLineBlock = ({ data, src, noImage, propName, link, href }) => (
  <div className='agent-data-line'>
    <div className='marker-container' style={{ alignItems: noImage ? 'flex-start' : 'center' }}>
      {!noImage && <img alt='marker' className='agent-data-pic' height={24} src={src} width={24} />}
      {propName && (
        <span className='address-names' style={{ paddingLeft: noImage ? '39px' : '' }}>
          {propName}
        </span>
      )}
      {link ? (
        <a href={href} rel='noopener noreferrer' target='_blank'>
          {data}
        </a>
      ) : (
        data
      )}
    </div>
  </div>
);

const LinedData = ({ data, overall }) => {
  const [isTruncated, setIsTruncated] = React.useState(true);

  const toggleTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  const resultArray = isTruncated ? data?.description?.slice(0, 600) : data?.description;

  function createMarkup() {
    return { __html: resultArray };
  }

  const buttonName = isTruncated ? 'More' : 'Less';

  const maskedPhone = `+${data?.agent?.phone
    ?.slice(0, 4)
    ?.replace(/(\d{2})/g, '$1 ')
    .trim()}  ${data?.agent?.phone?.slice(4, 7)} ${data?.agent?.phone
    ?.slice(7)
    ?.replace(/(\d{2})/g, '$1 ')
    .trim()} `;

  // cut agency name from addres line if it was there
  const agentAddress = data?.agent?.address?.replace(`${data?.agent?.name},`, '');

  return (
    <Grid className='desc-and-listed' container>
      {!overall && (
        <>
          <Grid className='desc' item lg={7} md={7} sm={6} xl={7} xs={12}>
            <div className='ParameterHeading'>About this home</div>
            <div className='desc-wrapper'>
              <span className='desc-text' dangerouslySetInnerHTML={createMarkup()} />
              {isTruncated && data?.description?.length > 600 && <span>...</span>}&nbsp;
              {data?.description?.length > 600 && (
                <span className='transparent-button truncText' onClick={toggleTruncated} role='button'>
                  {buttonName}
                </span>
              )}
            </div>
            {data?.features && data?.features?.length > 0 && (
              <>
                <div className='ParameterHeading'>FEATURES</div>
                <Grid className='feat-container' container>
                  <ul className='lists'>
                    {data?.features
                      .toString()
                      .split(',')
                      ?.map((el) => (
                        <li key={el} className='feat-item'>
                          {el?.toLowerCase()}
                        </li>
                      ))}
                  </ul>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item lg={5} md={5} sm={6} xl={5} xs={12}>
            <div className='left-block-part'>
              <div className='ParameterHeading'>Listed by:</div>

              {data.agent?.site ? (
                <div className='agent-data-line'>
                  <a
                    className='agent-data-line-link'
                    href={data?.agent?.site}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <span className='agent-name'>{data?.agent?.name}</span>
                  </a>
                </div>
              ) : (
                <div className='agent-data-line'>
                  <span className='agent-name'>{data?.agent?.name}</span>
                </div>
              )}
              {data?.agent?.city && <DataLineBlock data={data?.agent?.city} propName='City:' src={marker} />}
              {data?.agent?.address && <DataLineBlock data={agentAddress} noImage propName='Address:' src={marker} />}
              {data?.reference && <DataLineBlock data={data?.reference} src={ref} />}
              {data?.agent?.phone && <DataLineBlock data={maskedPhone} src={phone} />}

              {!overall && (
                <DataLineBlock
                  data={data?.dataSource?.sourceName}
                  href={data.url}
                  link
                  propName='Link:'
                  src={hyperlink}
                />
              )}
              {data?.address && (
                <>
                  <div className='ParameterHeading'>Address: </div>
                  <DataLineBlock data={data?.address} src={marker} />
                  <div className='empty-field' />
                </>
              )}
              <MapContainer data={data} overall={overall} />
            </div>
          </Grid>
        </>
      )}
      {overall && (
        <Grid item lg={8} md={11} sm={11} xl={7} xs={12}>
          <div className='heading-listed'>Listed by:</div>
          <div className='agent-list-wrap'>
            <AgentListItem home={data} noWrap overall />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default LinedData;
