/* eslint-disable no-console */
import React from 'react';
import { Skeleton } from '@mui/lab';
import { addError } from '../store/errorSlice';
import { setIsLogin } from '../store/isLogin';
import errorOnlyToast from './errorOnlyToast';
import errorRedirectWithStore from './errorRedirectWithStore';

export const noRenderer = (isLoading) => {
  if (isLoading) {
    return (
      <>
        <Skeleton animation='wave' height={15} style={{ marginTop: 10 }} />
        <Skeleton animation='wave' height={15} style={{ marginTop: 10 }} />
        <Skeleton animation='wave' height={15} style={{ marginTop: 10 }} />
      </>
    );
  }
  return <div className='no-rows-message'>No data matching criteria</div>;
};

export const user = () => {
  localStorage.getItem('mls');
};

// check if user object in Local store is valid JSON
export const checkJSONUserObj = (dispatch) => {
  try {
    const userObj = localStorage.getItem('mls');
    if (userObj) {
      JSON.parse(userObj);
    } else {
      dispatch(setIsLogin(false));
    }
  } catch (e) {
    dispatch(setIsLogin(false));
    dispatch(addError({ message: 'Please log in to continue', status: 401 }));
    localStorage.removeItem('mls');
  }
};

// call api with adding to store
export const apiCallPost = async (link, body, dispatch, rejectWithValue) => {
  checkJSONUserObj(dispatch, setIsLogin);
  const { token } = JSON.parse(localStorage.getItem('mls')) || '';
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  try {
    const response = await fetch(link, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const res = await response.json();
      return res;
    }
    if (!response.ok) {
      const e = response.status;
      throw new Error(e);
    }
  } catch (e) {
    errorRedirectWithStore(e, dispatch, rejectWithValue);
  }
};

// call api with adding to store
export const apiCallGet = async (link, dispatch, rejectWithValue) => {
  checkJSONUserObj(dispatch, setIsLogin);
  const { token } = JSON.parse(localStorage.getItem('mls')) || '';
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  try {
    const response = await fetch(link, {
      method: 'GET',
      headers,
    });
    if (response.ok) {
      const res = await response.json();
      return res;
    }
    if (!response.ok) {
      const e = response.status;
      throw new Error(e);
    }
  } catch (e) {
    errorRedirectWithStore(e, dispatch, rejectWithValue);
  }
};

// call api without adding to store
export const apiCallNoStoreGet = async (link) => {
  const { token } = JSON.parse(localStorage.getItem('mls'));
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  try {
    const response = await fetch(link, {
      method: 'GET',
      headers,
    });
    if (response.ok) {
      const res = await response.json();
      return res;
    }
    if (!response.ok) {
      const e = response.status;
      throw new Error(e);
    }
  } catch (e) {
    errorOnlyToast(e);
  }
};

// call api without adding to store
export const apiCallNoStorePost = async (link, str) => {
  const { token } = JSON.parse(localStorage.getItem('mls'));
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  try {
    const response = await fetch(link, {
      method: 'POST',
      headers,
      body: JSON.stringify(str),
    });
    if (response.ok) {
      const res = await response.json();
      return res;
    }
    if (!response.ok) {
      const e = response.status;
      throw new Error(e);
    }
  } catch (e) {
    errorOnlyToast(e);
  }
};

export const priceToShow = (obj) => {
  if ((!obj.price && obj.currency) || (!obj.price && !obj.currency)) {
    return 'Price on request';
  }
  if (obj.price && !obj.currency) {
    return obj?.price?.toLocaleString();
  }
  if (obj.price && obj.currency) {
    return ` ${obj.currency} ${obj.price?.toLocaleString()}`;
  }
};

export const showPriceRange = (data, setPriceOverall, currency) => {
  const pricesAll = data?.offers?.map((el) => el?.price)?.filter((el) => el);
  const checkSamePrice = (a) => [...new Set(a)].length === 1;
  const resultSame = checkSamePrice(pricesAll);
  if (pricesAll && pricesAll?.length === 0) {
    setPriceOverall('Price on request');
  } else if (pricesAll && pricesAll?.length === 1) {
    setPriceOverall(`${currency} ${pricesAll[0]?.toLocaleString()}`);
  } else if (resultSame) {
    setPriceOverall(`${currency} ${data?.offers[0]?.price?.toLocaleString()}`);
  } else if (pricesAll && pricesAll.length > 1 && !resultSame) {
    const min = Math.min(...pricesAll);
    const max = Math.max(...pricesAll);
    setPriceOverall(`${currency} ${min?.toLocaleString()} - ${max?.toLocaleString()}`);
  }
};
