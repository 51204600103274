import { createSlice } from '@reduxjs/toolkit';

export const filterLogSlice = createSlice({
  name: 'filtersLog',
  initialState: [],
  reducers: {
    addFilterLog: (state, action) => action.payload,
    removeFilterLog: () => [],
  },
});

export default filterLogSlice.reducer;
export const { addFilterLog, removeFilterLog } = filterLogSlice.actions;
