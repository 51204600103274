import Popover from '../popover/Popover';
import './SortOptions.css';

function SortOptions({ selectedSorters, dispatch }) {
  return (
    <div className='sort-menu'>
      <Popover
        dataKey='name'
        dispatch={dispatch}
        item={selectedSorters.name}
        label='Name'
        selectedSorters={selectedSorters}
      />
      <Popover
        dataKey='square'
        dispatch={dispatch}
        item={selectedSorters.square}
        label='Square'
        selectedSorters={selectedSorters}
      />
      <Popover
        dataKey='roomsCount'
        dispatch={dispatch}
        item={selectedSorters.roomsCount}
        label='Rooms'
        selectedSorters={selectedSorters}
      />
      <Popover
        dataKey='bedroomsCount'
        dispatch={dispatch}
        item={selectedSorters.bedroomsCount}
        label='Bedrooms'
        selectedSorters={selectedSorters}
      />
      <Popover
        dataKey='bathroomsCount'
        dispatch={dispatch}
        item={selectedSorters.bathroomsCount}
        label='Bathrooms'
        selectedSorters={selectedSorters}
      />
    </div>
  );
}

export default SortOptions;
