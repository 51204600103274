import * as Yup from 'yup';

const regexEmail =
  // eslint-disable-next-line no-control-regex,no-empty-character-class,max-len
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Too Short!').max(500, 'Too Long!'),
});

export const ValidationOnLogin = Yup.object({
  email: Yup.string().trim().matches(regexEmail, 'This is not email').required('Please, enter email'),
  password: Yup.string()
    .trim()
    .matches(
      regexPassword,
      'The password must be at least 6 characters, include a number, an uppercase and an lowercase letter'
    )
    .required('Please, enter password'),
});

// in case build fails
// export const validationOnLogin = Yup.object({
//   email: Yup.string().required('Please, enter email').email('This is not email'),
//   password: Yup.string().required('Please, enter password').min(6, 'Too Short!').max(15, 'Too Long!'),
// });
