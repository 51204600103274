import config from '../config';
import { apiCallNoStorePost } from '../utils/functions';

const linkGetAdvices = config.baseURL + config.api + config.autocomplete + config.searchFilters;

const getAutocomplete = async (str) => {
  const array = await apiCallNoStorePost(linkGetAdvices, str);
  return array;
};

export default getAutocomplete;
