import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ImageGallery from 'react-image-gallery';

import config from '../../../config';
import X from '../../../images/x.svg';
import './ModalImageGallery.css';

const style = {
  position: 'absolute',
  top: '54%',
  left: '50%',
  height: 'auto',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: '#3d3d3d',
  boxShadow: 24,
  p: 3,
};

const baseURL = config.baseURL + config.storage;

function CloseBtn({ handleClose }) {
  return (
    <button className='closeButtonModal' onClick={handleClose} type='button'>
      <img alt='close' src={X} />
    </button>
  );
}

export default function GalleryModal({ open, handleClose, imagesData, imageIndex }) {
  const imagesToTake = () => {
    if (imagesData?.images) {
      return imagesData?.images.map((el) => `${baseURL}${el}`);
    }
    return imagesData?.originalImages;
  };

  const imagesArray = imagesToTake()
    ?.filter((el) => !el.includes('userfiles'))
    ?.map((item) => ({
      original: item,
      thumbnail: item,
      originalClass: 'ImageModal',
      thumbnailClass: 'ThumbModal',
      originalHeight: '400px',
    }));

  return (
    <Modal
      aria-describedby='modal-modal-description'
      aria-labelledby='modal-modal-title'
      onClose={handleClose}
      open={open}
    >
      <Box sx={style}>
        <CloseBtn handleClose={handleClose} />
        <ImageGallery items={imagesArray} showFullscreenButton={false} startIndex={imageIndex} />
      </Box>
    </Modal>
  );
}
