/* eslint-disable no-shadow */
import * as React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import '../styles/SettingPage.css';

function WeekButton({ el, minWidth, func, arr }) {
  return (
    <button className={arr.includes(el) ? 'selected' : 'week-button'} onClick={func} style={{ minWidth }} type='button'>
      {el}
    </button>
  );
}

export default function Settings() {
  const week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const countries = ['Switzerland', 'Italy', 'Croatia', 'United Kingdom'];
  const cities = ['Verbier', 'Variant 1', 'Variant 2'];

  const [selectedWeekDay, setSelectedWeekDay] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');

  const chooseWeekDay = (event) => {
    const day = event.target.textContent;
    if (!selectedWeekDay.includes(day)) {
      setSelectedWeekDay((selectedWeekDay) => [...selectedWeekDay, day]);
    } else {
      setSelectedWeekDay(selectedWeekDay.filter((el) => el !== day));
    }
  };

  const chooseCountry = (event) => {
    const country = event.target.textContent;
    if (!selectedCountry.includes(country)) {
      setSelectedCountry((selectedCountry) => [...selectedCountry, country]);
    } else {
      setSelectedCountry(selectedCountry.filter((el) => el !== country));
    }
  };

  const chooseCity = (event) => {
    const city = event.target.textContent;
    if (!selectedCity.includes(city)) {
      setSelectedCity((selectedCity) => [...selectedCity, city]);
    } else {
      setSelectedCity(selectedCity.filter((el) => el !== city));
    }
  };

  return (
    <Box className='outer-box'>
      <div className='header'>Algorythm</div>
      <hr className='line' />
      <Box className='inner-box'>
        <div className='first-column'>
          <div>Last update:</div>
          <div>Training samplesize:</div>
          <div>Accuracy:</div>
        </div>
        <div className='second-column'>
          <div>22.11.2021</div>
          <div>98</div>
          <div>2000</div>
        </div>
      </Box>
      <div className='second-header'>Review</div>
      <hr className='line' />
      <Box className='inner-box'>
        <div className='second-column'>
          <div>Min price:</div>
          <div>Accuracy:</div>
          <div>Price difference:</div>
        </div>
        <PopupState popupId='demoPopover' variant='popover'>
          {(popupState) => (
            <>
              <div className='second-column-left' {...bindHover(popupState)}>
                <TextField className='textField' defaultValue='| Text' />
                <TextField className='textField' defaultValue='| Text' />
                <TextField className='textField' defaultValue='| Text' />
              </div>
              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ width: '200px', padding: '20px' }}>This data can be edited</Typography>
              </HoverPopover>
            </>
          )}
        </PopupState>
      </Box>
      <div className='second-header'>Data</div>
      <hr className='line' />
      <Box className='inner-box' style={{ minHeight: '188px' }}>
        <div className='forth-column'>
          <div>MSchedule:</div>
          <div>Start time:</div>
          <div>Countries:</div>
          <div>Regions:</div>
          <div>Last update:</div>
        </div>
        <div className='forth-column-left'>
          <div className='week-days'>
            {week.map((el) => (
              <WeekButton key={el} arr={selectedWeekDay} el={el} func={chooseWeekDay} id={el} minWidth={54} />
            ))}
          </div>
          <div className='textFieldWrapper'>
            <TextField className='textField' defaultValue={new Date().toDateString()} />
          </div>
          <div className='week-days'>
            {countries.map((el) => (
              <WeekButton key={el} arr={selectedCountry} el={el} func={chooseCountry} minWidth={56} />
            ))}
          </div>
          <div className='week-days'>
            {cities.map((el) => (
              <WeekButton key={el} arr={selectedCity} el={el} func={chooseCity} minWidth={72} />
            ))}
            <TextField className='textFieldNarrow' defaultValue='Enter city' />
          </div>
          <div className='date'>03.02.2022</div>
        </div>
      </Box>
    </Box>
  );
}
