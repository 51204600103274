export const mapsData = [
  {
    id: 1,
    country: 'Switzerland',
    coords: {
      lat: 46.8182,
      lng: 8.2275,
    },
  },
  {
    id: 2,
    country: 'UAE',
    coords: {
      lat: 25.276987,
      lng: 55.296249,
    },
  },
];

const coords = {
  verbier: {
    lat: 46.09989,
    lng: 7.22653,
  },
  cransMontana: {
    lat: 46.307165438,
    lng: 7.476331428,
  },
  anderMatt: {
    lat: 46.3808,
    lng: 8.3537,
  },

  zerMatt: {
    lat: 46.0213,
    lng: 7.7491,
  },
};

export default coords;
