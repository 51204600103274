import { createAsyncThunk } from '@reduxjs/toolkit'

import config from '../config'
import { apiCallPost } from '../utils/functions'

const linkGetHomes = config.baseURL + config.api + config.estateOffer + config.search

export const getTable = createAsyncThunk('offers/addOfferArray', async (body, { rejectWithValue, dispatch }) => {
  const array = await apiCallPost(linkGetHomes, body, dispatch, rejectWithValue)
  return array
})

export const getMoreRowsOfTable = createAsyncThunk(
  'offers/addMoreOfferArray',
  async (body, { rejectWithValue, dispatch }) => {
    const array = await apiCallPost(linkGetHomes, body, dispatch, rejectWithValue)
    return array
  }
)
