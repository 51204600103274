/* eslint-disable no-unsafe-optional-chaining */
import { useState, useEffect } from 'react';
import { Skeleton, Grid, Pagination, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { getFavs } from '../api/getFavs';
import ShowTotals from '../components/showTotals/ShowTotals';
import '../styles/ObjectPage.css';
import EstateBigTile from '../components/estateBigTile/EstateBigTile';

function Favourites() {
  const [scrollTopPos, setScrollTopPos] = useState(true);

  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filtersObject);
  const favsObject = useSelector((state) => state?.favs?.favs);
  const flag = useSelector((state) => state?.flag);
  const totalQty = favsObject?.total;
  const data = favsObject?.rows;

  // useEffect(() => {
  //   if (flag) {
  //     dispatch(getFavs());
  //   }
  //   setScrollTopPos(true);
  // }, [filters, dispatch]);

  return (
    <div className='object-wrapper'>
      <div className='skeleton-cont'>
        <ShowTotals favs flag={flag} grandTotalQty={totalQty} page='Objects' totalQty={totalQty} />
        {/*   <SortOptions dispatch={dispatch} selectedSorters={sort} /> */}
      </div>
      {data ? (
        <Grid container spacing={2} sx={{ position: 'relative' }}>
          {data.map((el) => (
            <Grid key={el._id} item lg={3} md={4} sm={6} xl={3} xs={12}>
              <EstateBigTile home={el} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </div>
  );
}

export default Favourites;
