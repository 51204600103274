import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiCallGet } from '../utils/functions'
import config from '../config'

const linkGetHomes = config.baseURL + config.api + config.estateObj

const getSingleObject = createAsyncThunk('singleObject/addLogArray', async (params, { rejectWithValue, dispatch }) => {
  const array = await apiCallGet(`${linkGetHomes}/${params.objectId}`, dispatch, rejectWithValue)
  return array
})

export default getSingleObject
