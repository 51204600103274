import {
  AttributesCell,
  CommonCell,
  FeatureCell,
  DescCell,
  PriceCell,
  ButtonCell,
  ChipCell,
  StatusCell,
} from './CellRenderers';
import ImageCell from './ImageCell';
import CollapseCell from './CollapseCell';

import './CellStyles.css';

function RowRenderer({
  index,
  key,
  style,
  rows,
  expanded,
  handleExpandClick,
  handleClose,
  selectedIndex,
  setSelectedIndex,
  handleOpenModal,
}) {
  return (
    <div key={key} style={style}>
      <div className='tableOffers' style={{ backgroundColor: index % 2 ? '#F8F8FF' : 'transparent' }}>
        <CollapseCell
          cellData={rows[index]?.isFav}
          expanded={expanded}
          handleExpandClick={handleExpandClick}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <ChipCell cellData={rows[index]?.obj} />
        <ButtonCell cellData={rows[index]?.sourceName} />
        <ImageCell cellData={rows[index]?.image} handleClose={handleClose} handleOpenModal={handleOpenModal} />
        <PriceCell cellData={rows[index]?.price} />
        <AttributesCell cellData={rows[index]?.attributes} />
        <FeatureCell cellData={rows[index]?.features} />
        <DescCell cellData={rows[index]?.description} />
        <CommonCell cellData={rows[index]?.buildingYear} />
        <CommonCell cellData={rows[index]?.renovationYear} />
        <CommonCell cellData={rows[index]?.address} />
        <CommonCell cellData={rows[index]?.city} />
        <CommonCell cellData={rows[index]?.country} />
        <CommonCell cellData={rows[index]?.reference} />
        <CommonCell cellData={rows[index]?.coords} />
        <CommonCell cellData={rows[index]?.createdAt} />
        <CommonCell cellData={rows[index]?.agent} />
        <CommonCell cellData={rows[index]?.agentAddress} />
        <CommonCell cellData={rows[index]?.phone} />
        <StatusCell cellData={rows[index]?.status} />
        <CommonCell cellData={rows[index]?.availableFrom} />
      </div>
    </div>
  );
}

export default RowRenderer;
