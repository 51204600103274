import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setTabNum } from '../../../store/allObjects';
import './PicTile.css';

const PicTile = ({ src, alt, height, width, title, text, val, favs }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const moveToTabs = () => {
    dispatch(setTabNum(val));
    navigate('/data');
  };

  return (
    <button className='picTile' onClick={moveToTabs} type='button'>
      {favs && <div className='fav-badge'>5 objects</div>}
      <img {...{ src, alt, height, width }} />
      <div className='textCont'>
        <h4 className='titleTile'>{title}</h4>
        <div className='textTile'>{text}</div>
      </div>
    </button>
  );
};

export default PicTile;
