/* eslint-disable react/no-array-index-key */
import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const handleRenderOption = (props, option, { inputValue }) => {
  const matches = match(option, inputValue);
  const parts = parse(option, matches);

  const highlightStyle = {
    fontWeight: 700,
    backgroundColor: '#ffff8d',
    padding: '5px 2px',
  };

  return (
    <li {...props}>
      <div>
        {parts.map((part, index) => (
          <span key={index} style={part.highlight ? highlightStyle : {}}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

export default handleRenderOption;
