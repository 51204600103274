/* eslint-disable react/no-danger */
import * as React from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import { priceToShow } from '../../../utils/functions';
import MainAttr from '../mainAttr/MainAttr';
import DescListed from '../descListed/DescListed';
import bed from '../../../images/bed1.svg';
import { addToFavs, removeFromFavs } from '../../../store/allObjects';
import { toggleStore } from '../../../store/singleObject';
import toggleFavsApi from '../../../api/toggleFavs';
import bath from '../../../images/bath.svg';
import living from '../../../images/livingSq.svg';
import rooms from '../../../images/rooms.svg';
import area from '../../../images/area.svg';
import GalleryModal from '../../Modals/fullScreenImageGallery/FullScreenImageGallery';
import './LinedData.css';

function LinedData({ data, overall, priceOverall, imagesData, imageIndex }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const checkAttr = () => {
    const attributes = ['bathroomsCount', 'bedroomsCount', 'square', 'plotSquare', 'livingSquare'];
    const res = Object.keys(data);
    return res.some((el) => attributes.includes(el));
  };

  const toggleFavs = (e, id) => {
    toggleFavsApi(id);
    if (data.isFavorite) {
      dispatch(toggleStore(false));
      dispatch(removeFromFavs(id));
    } else {
      dispatch(addToFavs(id));
      dispatch(toggleStore(true));
    }
  };

  return (
    <>
      <div className='PageMobile'>
        <button className='IconButtonLast' onClick={handleOpen} type='button'>
          view all pictures
        </button>
        <Grid className='name-container' container>
          <Grid className='home-title-lined' item lg={7} md={7} sm={12} xl={8} xs={12}>
            {data.name || data.specifiedName}
            {!overall && (
              <div className='SumOwnLine'>
                <div className='Sum'>{priceToShow(data)}</div>
              </div>
            )}
            {overall && (
              <div className='SumOwnLine'>
                <div className='Sum'>{priceOverall}</div>
              </div>
            )}
          </Grid>
          <Grid className='Icons' item lg={5} md={5} sm={12} xl={4} xs={12}>
            {overall && (
              <button
                className={data.isFavorite ? 'IconButton3' : 'IconButton4'}
                onClick={(e) => toggleFavs(e, data._id)}
                type='button'
              />
            )}
          </Grid>
        </Grid>
        <GalleryModal handleClose={handleClose} imageIndex={imageIndex} imagesData={imagesData} open={open} />
        <div className='RowLine'>
          <hr />
        </div>
        <Grid container>
          <Grid className='SecondBlock' item xs={5}>
            {checkAttr() && (
              <>
                <div className='ParameterHeading'>Attributes</div>
                <div className='ParameterText'>
                  {data?.square > 0 && (
                    <div className='one-feature'>
                      <div className='IconPic'>
                        <img alt='area' height={21} src={area} width={20} />
                      </div>
                      <span className='X10'>
                        {data?.square && data?.square?.toLocaleString()}&nbsp; m<sup>2</sup>
                      </span>
                      <br />
                    </div>
                  )}
                  {data?.livingSquare > 0 && (
                    <div className='one-feature'>
                      <div className='IconPic'>
                        <img alt='house' height={20} src={living} width={20} />
                      </div>
                      <div className='X10'>
                        {data?.livingSquare?.toLocaleString()}&nbsp; m<sup>2</sup>
                      </div>
                    </div>
                  )}
                  {data?.plotSquare > 0 && (
                    <div className='one-feature'>
                      <div className='IconPic'>
                        <img alt='area' height={21} src={area} width={20} />
                      </div>
                      <div className='X10'>
                        {data?.plotSquare && data?.plotSquare?.toLocaleString()}&nbsp; m<sup>2</sup>
                      </div>
                      <br />
                    </div>
                  )}
                  {data?.roomsCount > 0 && (
                    <div className='one-feature'>
                      <div className='IconPic'>
                        <img alt='house' height={20} src={rooms} width={20} />
                      </div>
                      <div className='X10'>{data?.roomsCount}&nbsp; rooms</div>
                    </div>
                  )}
                  {data?.bedroomsCount > 0 && (
                    <div className='one-feature'>
                      <div className='IconPic'>
                        <img alt='bed' height={21} src={bed} width={21} />
                      </div>
                      <div className='X10'> {data.bedroomsCount}&nbsp; beds</div>
                    </div>
                  )}
                  {data?.bathroomsCount > 0 && (
                    <div className='one-feature'>
                      <div className='IconPic'>
                        <img alt='bath' height={23} src={bath} width={23} />
                      </div>
                      <div className='X10'> {data.bathroomsCount}&nbsp; baths</div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={7}>
            <MainAttr data={data} />
          </Grid>
        </Grid>
        <div className='RowLine'>
          <hr />
        </div>
      </div>
      <DescListed data={data} overall={overall} />
    </>
  );
}

export default LinedData;
