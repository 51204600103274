/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { TableCell } from '@mui/material';
// import { styled } from '@mui/material/styles'
// import IconButton from '@mui/material/IconButton'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch } from 'react-redux';

import toggleFavsApi from '../../../api/toggleFavs';
import { addOfferFav, removeOfferFav } from '../../../store/allOffers';
import { getFavs } from '../../../api/getFavs';

import './CellStyles.css';

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props
//   return <IconButton {...other} />
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }))

export default function CollapseCell({
  cellData,
  // rowIndex,
  // setSelectedIndex,
  // handleExpandClick,
  // expanded,
  // selectedIndex,
  // getDataBulk,
}) {
  const dispatch = useDispatch();

  const toggleFavs = (e, id) => {
    e.stopPropagation();
    toggleFavsApi(id);
    if (!cellData?.isFavorite) {
      dispatch(addOfferFav(id));
    } else {
      dispatch(removeOfferFav(id));
    }
    setTimeout(() => dispatch(getFavs()), 500);
  };

  return (
    <TableCell className='collapse-cell' component='div' sx={{ paddingTop: '21px', border: 'none' }}>
      <button
        className={cellData?.isFavorite ? 'grey-heart-offer' : 'white-heart-offer'}
        onClick={(e) => toggleFavs(e, cellData?.id)}
        type='button'
      />
      {/*   {cellData.connectedId.length > 0 && (
        <ExpandMore
          aria-label='show more'
          className='expand-button'
          expand={rowIndex === selectedIndex ? expanded : false}
          onClick={() => {
            handleExpandClick()
            if (rowIndex !== selectedIndex) {
              setSelectedIndex(rowIndex)
              getDataBulk(cellData.connectedId)
            }
          }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
        )} */}
    </TableCell>
  );
}
