import { Tabs, Tab, AppBar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import db from '../../../images/dbBl.svg';
import dbTwo from '../../../images/dbBlack.svg';
import hom from '../../../images/hom.svg';

import { setTabNum } from '../../../store/allObjects';
import './StickyMenu.css';

const StickyMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabStyle = {
    fontFamily: 'Montserrat',
    color: '#000 !important',
    minHeight: 80,
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: 'rgba(126, 124, 207,0.1)',
    },
  };

  const value = useSelector((state) => state?.objects?.tabNum);

  const navigateData = (num) => {
    navigate('/data');
    dispatch(setTabNum(num));
  };

  return (
    <AppBar className='appBarWhite' position='fixed' sx={{ top: 'auto', bottom: 0, margin: 0 }}>
      <Tabs
        aria-label='nav-pages'
        sx={{
          '& .MuiTabs-scrollButtons.Mui-disabled': {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          '&& .MuiTab-root': {
            '@media (max-width: 600px)': {
              minWidth: '60px',
              fontSize: 10,
              padding: '12px',
              justifyContent: 'flex-start',
            },
          },
          '&& .Mui-selected': {
            backgroundColor: '#ffffff',
            color: '#7E7CCF',
          },
          '&& .MuiPaper-root': {
            alignItems: 'center !important',
          },
        }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'transparent',
          },
        }}
        value={value}
        variant='fullWidth'
      >
        <Tab
          icon={<img alt='db' height={20} src={hom} sx={{ paddingRight: 5 }} width={20} />}
          iconPosition='top'
          label='Main'
          onClick={() => navigate('/')}
          sx={tabStyle}
        />
        <Tab
          icon={<img alt='db' height={20} src={db} sx={{ paddingRight: 5 }} width={20} />}
          iconPosition='top'
          label='Property listings'
          onClick={() => navigateData(0)}
          sx={tabStyle}
        />
        <Tab
          icon={<img alt='offers' height={20} src={dbTwo} sx={{ paddingRight: 5 }} width={20} />}
          iconPosition='top'
          label='Agents’ listing'
          onClick={() => navigateData(1)}
          sx={tabStyle}
          wrapped
        />
        <Tab
          icon={<PendingOutlinedIcon fontSize='small' sx={{ color: '#3d3d3d', opacity: 0.8 }} />}
          iconPosition='top'
          label='More'
          sx={tabStyle}
          wrapped
        />
      </Tabs>
    </AppBar>
  );
};

export default StickyMenu;
