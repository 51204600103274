import { Button, styled, Badge } from '@mui/material';

export const TransparentButton = styled(Button)({
  textTransform: 'capitalize',
  boxShadow: 'none',
  fontSize: 14,
  border: '1px solid',
  borderColor: '#ccdodb',
  color: '#3D3D3D',
  width: 118,
  height: 42,
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: 30,
  fontWeight: 600,
  '@media (max-width: 600px)': {
    width: 'auto',
    minWidth: 44,
  },
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 5px',
    backgroundColor: '#3d3d3d',
  },
}));
