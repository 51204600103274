import * as React from 'react';

import TopHeader from '../components/layoutComponents/topHeader';
import StickyMenu from '../components/layoutComponents/stickyMenu';

function Layout({ children }) {
  return (
    <>
      <div>
        <TopHeader />
      </div>
      <main>{children}</main>
      <div>
        <StickyMenu />
      </div>
    </>
  );
}

export default Layout;
