import './CellStyles.css';
import { CommonCell, MessageCell } from './CellRenderers';
import StatusCell from './StatusCell';

function RowRendererLogs({ index, key, style, rows }) {
  return (
    <div key={key} style={style}>
      <div className='tableLogs' style={{ backgroundColor: index % 2 ? '#f6f6f4' : 'transparent' }}>
        <StatusCell cellData={rows[index]?.status} />
        <CommonCell cellData={rows[index]?.created} />
        <CommonCell cellData={rows[index]?.dataSourceName} />
        <MessageCell cellData={rows[index]?.message} />
        <CommonCell cellData={rows[index]?.dataSourceId} />
      </div>
    </div>
  );
}

export default RowRendererLogs;
