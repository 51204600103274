import { createTheme, ThemeProvider } from '@mui/material/styles';

import GreenGreyButton from '../greenGreyButton';
import './AgentListItem.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00BC00',
    },
    secondary: {
      main: '#8B8B8B',
    },
  },
});

function AgentListItem({ home, noWrap, overall }) {
  const classes = noWrap ? 'agent-list-no-wrap' : 'agent-list-wrapper';

  return (
    <ThemeProvider theme={theme}>
      <div className={classes}>
        {home &&
          home?.offers &&
          home?.offers?.map((el) => (
            <div key={el._id || el.url} className={overall ? 'agent-list-item' : 'agent-list-item-no-date'}>
              <GreenGreyButton color={el?.status?.toLowerCase() === 'active' ? 'green' : 'grey'} status={el?.status} />
              {el?.agent?.site ? (
                <a
                  className='url-link'
                  href={el?.agentSite || el?.agent?.site}
                  onClick={(e) => e.stopPropagation()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <span style={{ paddingLeft: '20px' }}>{el?.agentName || el?.agent?.name || 'NO NAME'}</span>
                </a>
              ) : (
                <span style={{ paddingLeft: '20px' }}>{el?.agentName || el?.agent?.name || 'NO NAME'}</span>
              )}
              <span>
                {
                  // eslint-disable-next-line no-nested-ternary
                  el?.price
                    ? el?.priceInterval
                      ? `${el?.price?.toLocaleString()} ${el?.currency}  / ${el?.priceInterval}`
                      : `${el?.price?.toLocaleString()} ${el?.currency} `
                    : 'on request'
                }
              </span>
              <span>
                <a
                  className='url-link'
                  href={el?.url}
                  onClick={(e) => e.stopPropagation()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {el?.sourceName || el?.dataSource?.sourceName}
                </a>
              </span>
              {overall && <span>upd. {new Date(el?.updatedAt).toLocaleDateString()}</span>}
            </div>
          ))}
      </div>
    </ThemeProvider>
  );
}

export default AgentListItem;
