/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import getSingleObject from '../api/getSingleObject';

export const oneObject = createSlice({
  name: 'singleObject',
  initialState: { oneObject: [], status: null, error: null },
  reducers: {
    removeObject: (state) => {
      state.oneObject = [];
    },
    toggleStore: (state, action) => {
      state.oneObject[0].isFavorite = action.payload;
    },
  },
  extraReducers: {
    [getSingleObject.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [getSingleObject.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.oneObject = action.payload;
    },
    [getSingleObject.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
  },
});

export default oneObject.reducer;
export const { toggleStore, removeObject } = oneObject.actions;
