import * as React from 'react';
import GoogleMapReact from 'google-map-react';

import coords from '../../../utils/citiesCenterCoords';
import './Map.css';

function Marker() {
  return <div className='Marker' />;
}

function MapContainer({ data }) {
  const defaultProps = {
    center: {
      lat: coords?.verbier?.latitude,
      lng: coords?.verbier?.longitude,
    },
    zoom: 11,
  };

  const [center, setCenter] = React.useState(defaultProps.center);

  React.useEffect(() => {
    if (data?.latitude && data?.longitude) {
      setCenter({ lat: Number(data.latitude), lng: Number(data.longitude) });
    }
    if (!data.latitude || !data.latitude) {
      const city = data?.city?.toLowerCase();
      setCenter(coords[city]);
    }
  }, [data]);

  return (
    <div style={{ height: '235px', width: 'auto', borderRadius: '10px', marginRight: 30, overflow: 'hidden' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDEC7Be6VjXYSDyJRnCGEQZR9yDULhMbB0' }}
        center={center}
        defaultZoom={defaultProps.zoom}
        options={{
          draggable: false,
        }}
      >
        {center && <Marker lat={center.lat} lng={center.lng} />}
      </GoogleMapReact>
    </div>
  );
}

export default MapContainer;
