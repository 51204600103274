/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getObjects } from '../api/getObject';

export const objectsArray = createSlice({
  name: 'objects',
  initialState: {
    tabNum: 0,
    objects: [],
    status: null,
    error: null,
    startIndex: 0,
    stopIndex: 36,
    hasData: false,
    offset: 0,
    limit: 36,
    rows: [],
    isLoading: false,
  },
  reducers: {
    setTabNum: (state, action) => {
      state.tabNum = action.payload;
    },
    setStartIndex: (state, action) => {
      state.startIndex = action.payload;
    },
    addToFavs: (state, action) => {
      state.rows = state.rows.map((el) => {
        if (el._id === action.payload) {
          return { ...el, isFavorite: true };
        }
        return el;
      });
    },
    removeFromFavs: (state, action) => {
      state.rows = state.rows.map((el) => {
        if (el._id === action.payload) {
          return { ...el, isFavorite: false };
        }
        return el;
      });
    },
    clearRows: (state) => {
      state.rows = [];
    },
  },
  extraReducers: {
    [getObjects.pending]: (state) => {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
    },
    [getObjects.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.objects = action.payload;
      state.rows = action.payload.rows;
      state.isLoading = false;
      state.hasData = true;
    },
    [getObjects.rejected]: (state, action) => {
      state.status = 'rejected';
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default objectsArray.reducer;
export const { addToFavs, removeFromFavs, clearRows, setStartIndex, setTabNum } = objectsArray.actions;
