/* eslint-disable no-console */
import * as React from 'react';
import { Button, CssBaseline, TextField, Grid, Box, Container, Snackbar, Paper } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';

import loginHome from '../images/loginHome.jpg';
import loginLock from '../images/loginLock.svg';
import errorHandler from '../utils/errorHandler';
import '../styles/Login.css';
import config from '../config';
import { ValidationOnLogin } from '../utils/validationYup';
import { setIsLogin } from '../store/isLogin';
import { addFlag } from '../store/loadFlag';

const Alert = React.forwardRef((props, ref) => <MuiAlert ref={ref} elevation={6} variant='filled' {...props} />);

const baseURL = config.baseURL + config.api + config.user + config.login;

const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: ValidationOnLogin,
    onSubmit: async (values) => {
      try {
        const response = await fetch(baseURL, {
          method: 'POST',
          headers,
          body: JSON.stringify(values),
        });
        if (response.ok) {
          dispatch(setIsLogin(true));
          dispatch(addFlag(true));
          const res = await response.json();
          const user = { userId: '', token: res.accessToken };
          localStorage.setItem('mls', JSON.stringify(user));
          navigate('/');
        }
        if (!response.ok) {
          const e = response.status;
          throw new Error(e);
        }
      } catch (e) {
        setErrorMessage(errorHandler(e.message));
        handleClick();
      }
    },
  });

  return (
    <Grid component='main' container sx={{ height: '100vh' }}>
      <Grid
        component={Paper}
        elevation={6}
        item
        md={6}
        square
        sx={{ display: 'flex', justifyContent: 'center', paddingTop: '270px' }}
        xs={12}
      >
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <img alt='lock' height={115} src={loginLock} width={120} /> */}
            <Box component='form' noValidate sx={{ mt: 5 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    color='secondary'
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    id='email'
                    label='email'
                    name='email'
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color='secondary'
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    fullWidth
                    helperText={formik.touched.password && formik.errors.password}
                    id='password'
                    label='password'
                    name='password'
                    onChange={formik.handleChange}
                    type='password'
                    value={formik.values.newPassword}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
              <Button
                className='button'
                fullWidth
                onClick={formik.handleSubmit}
                sx={{ color: '#ffffff !important', mt: 3, mb: 2 }}
                type='submit'
                variant='contained'
              >
                Login
              </Button>
            </Box>
            <div style={{ marginTop: '40px' }}>
              {/* eslint-disable-next-line max-len */}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeGX7FmJW8XeQj0gYs7RTRfm5sRhlGRVcfLquvuog2YgVsplw/viewform?usp=sf_link"
                rel="noreferrer"
                style={{ color: 'black' }}
                target="_blank"
              >
                Contact us
              </a>
            </div>
            <Snackbar autoHideDuration={6000} onClose={handleClose} open={open}>
              <Alert onClose={handleClose} severity='error'>
                {errorMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          backgroundImage: `url(${loginHome})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        xs={false}
      />
    </Grid>
  );
}
