import { Typography } from '@mui/material';
import './GreenGreyButton.css';

const GreenGreyButton = ({ status, color, red }) => {
  const style = () => {
    if (!red) {
      if (color === 'green') {
        return 'activeBtn';
      }
      return 'removedBtn';
    }
    if (color === 'green') {
      return 'activeBtn';
    }
    return 'redBtn';
  };

  return (
    <div className={style()}>
      <Typography sx={{ fontWeight: 600 }}>{status?.toLowerCase()}</Typography>
    </div>
  );
};

export default GreenGreyButton;
