import { toast } from 'react-toastify';
import errorHandler from './errorHandlerTokenError';

const errorOnlyToast = (e) => {
  const message = errorHandler(e.message);
  toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    theme: 'colored',
    toastId: 'error1',
  });
  if (e.message == 401 || e.message == 403) {
    localStorage.removeItem('mls');
  }
};

export default errorOnlyToast;
