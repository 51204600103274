/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getFavs, getMoreRowsFavs } from '../api/getFavs';

export const favsArray = createSlice({
  name: 'favs',
  initialState: { favs: [], status: null, error: null },
  reducers: {
    addToFavsStore: (state, action) => {
      state.favs.rows = state.favs.rows.concat(action.payload);
      state.favs.total += 1;
    },
    removeFromFavsStore: (state, action) => {
      state.favs.rows = state.favs.rows.filter((el) => el._id !== action.payload);
      state.favs.total -= 1;
    },
  },
  extraReducers: {
    [getFavs.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [getFavs.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.favs = action.payload;
    },
    [getFavs.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
    [getMoreRowsFavs.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [getMoreRowsFavs.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.favs.rows = state.favs.rows.concat(action.payload.rows);
    },
    [getMoreRowsFavs.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
  },
});

export default favsArray.reducer;
export const { removeFromFavsStore, addToFavsStore } = favsArray.actions;
