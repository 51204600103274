import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import ImageGallery from 'react-image-gallery';

import { TitleCell } from './CellRenderers';
import config from '../../../config';

const baseURL = config.baseURL + config.storage;

export default function ImageCell({ cellData, rowIndex, handleOpenModal }) {
  const imagesToTake = () => {
    if (cellData?.images) {
      return cellData?.images.map((el) => `${baseURL}${el}`);
    }
    return cellData?.originalImages;
  };

  const imageArray = imagesToTake()
    ?.filter((el) => !el.includes('userfiles'))
    ?.map((el) => ({ original: el, originalClass: 'table-pics' }));

  const handleCheck = (e, dataFromCell) => {
    e.stopPropagation();
    handleOpenModal(dataFromCell);
  };

  return (
    <TableCell
      className='table-slider'
      component='div'
      id={rowIndex}
      sx={{
        flexDirection: 'column',
        height: 'auto',
        pr: 0,
        pl: 0,
        pt: '10px',
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
        background: 'transparent',
        position: 'relative',
      }}
    >
      <TitleCell cellData={cellData} />
      {cellData?.images || cellData?.originalImages ? (
        <>
          <button className='link-layer' onClick={(e) => handleCheck(e, cellData)} type='button' />
          <ImageGallery
            items={imageArray}
            lazyLoad
            showFullscreenButton={false}
            showPlayButton={false}
            stopPropagation
          />
        </>
      ) : (
        <Typography>No image</Typography>
      )}
    </TableCell>
  );
}
