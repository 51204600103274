const errorHandler = (status) => {
  switch (true) {
    case status === '403':
      return 'The password is incorrect'
    case status === '401':
      return 'Please log in to continue'
    case status === '404':
      return 'There is no user with these credentials'
    default:
      return 'Something went wrong, please try again later'
  }
}

export default errorHandler
