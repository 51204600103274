import { createAsyncThunk } from '@reduxjs/toolkit';

import config from '../config';
import { apiCallPost } from '../utils/functions';

const linkGetHomes = config.baseURL + config.api + config.estateObj + config.search;

export const getObjects = createAsyncThunk('objects/addObjectArray', async (body, { dispatch, rejectWithValue }) => {
  const array = await apiCallPost(linkGetHomes, body, dispatch, rejectWithValue);
  return array;
});

export const getMoreRowsOfObjects = createAsyncThunk(
  'objects/addMoreObjectArray',
  async (body, { dispatch, rejectWithValue }) => {
    const array = await apiCallPost(linkGetHomes, body, dispatch, rejectWithValue);
    return array;
  }
);
