/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getLogs } from '../api/getLogs';

export const logsArray = createSlice({
  name: 'logs',
  initialState: {
    logs: [],
    status: null,
    error: null,
    scrollToIndex: undefined,
    hasData: false,
    scrollY: 0,
    offset: 0,
    limit: 30,
    rows: [],
    isLoading: false,
  },
  reducers: {
    setScrollY: (state, { payload }) => {
      state.scrollY = payload;
    },
    unmount: (state) => {
      state.scrollToIndex = parseInt(`${state.scrollY / 100}`, 10) + 5;
    },
  },
  extraReducers: {
    [getLogs.pending]: (state) => {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
    },
    [getLogs.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.logs = action.payload;
      const { rows, offset } = action.payload;
      for (let i = 0; i < rows.length; i += 1) {
        state.rows[offset + i] = rows[i];
      }
      state.isLoading = false;
      state.hasData = true;
    },
    [getLogs.rejected]: (state, action) => {
      state.status = 'rejected';
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default logsArray.reducer;
export const { setScrollY, unmount } = logsArray.actions;
