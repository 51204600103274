import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
  name: 'error',
  initialState: '',
  reducers: {
    addError: (state, action) => action.payload,
    removeError: () => '',
  },
});

export default errorSlice.reducer;
export const { addError, removeError } = errorSlice.actions;
