import { createSlice } from '@reduxjs/toolkit'

export const flagSlice = createSlice({
  name: 'flag',
  initialState: true,
  reducers: {
    addFlag: (state, action) => action.payload,
  },
})

export default flagSlice.reducer
export const { addFlag } = flagSlice.actions
