import { Skeleton } from '@mui/material';

import './ShowTotals.css';

function ShowTotals({ totalQty, grandTotalQty, page, favs, flag }) {
  switch (true) {
    case !flag:
      return <div className='total' />;
    case !grandTotalQty && grandTotalQty !== 0 && !favs && flag:
      return (
        <div className='total'>
          <Skeleton width={300} />
        </div>
      );
    case !grandTotalQty && favs && flag:
      return <div className='total'>No favorites selected</div>;
    case totalQty === grandTotalQty && flag:
      return <div className='total'>{`${page} displayed: ${totalQty}`}</div>;
    case totalQty !== grandTotalQty && flag:
      return (
        <div className='total'>
          {`${page} displayed: `}
          <span style={{ color: '#7E7CCF' }}>&nbsp;{`${totalQty} from ${grandTotalQty}`}</span>
        </div>
      );

    default:
      return <Skeleton width={300} />;
  }
}

export default ShowTotals;
