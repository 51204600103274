import * as React from 'react';
import { Typography, Box, TextField } from '@mui/material';

import '../filterDropdown/FilterDropdown.css';

export default function FilterRange({ labelMin, labelMax, label, formik, name, id, array, setTyping }) {
  const minName = `${name}.min`.toString();
  const maxName = `${name}.max`.toString();

  const handleChangeMin = (e) => {
    setTyping(true);
    const { value } = e.target;
    const changedVal = Number(value.replace(/\D/g, '')).toLocaleString();
    formik.setFieldValue(minName, changedVal);
    setTimeout(() => setTyping(false), 3000);
  };

  const handleChangeMax = (e) => {
    setTyping(true);
    const { value } = e.target;
    const changedVal = Number(value.replace(/\D/g, '')).toLocaleString();
    formik.setFieldValue(maxName, changedVal);
    setTimeout(() => setTyping(false), 3000);
  };

  return (
    <div className='range-wrap'>
      <Typography sx={{ width: '100%', fontWeight: 600, fontSize: 14, pb: 1 }}>{label}</Typography>
      <Box
        autoComplete='off'
        component='form'
        disableScrollLock
        noValidate
        sx={{
          '& > :not(style)': { mt: 1, mb: 1, mr: 3, width: '15ch', '@media (max-width: 500px)': { width: '45%' } },
        }}
      >
        <TextField
          id={id}
          InputLabelProps={{ shrink: true }}
          label={labelMin}
          name={minName}
          onChange={(e) => handleChangeMin(e)}
          placeholder={array.min.toLocaleString()}
          size='small'
          sx={{
            '&& .MuiOutlinedInput-notchedOutline': {
              borderRadius: 16,
            },
          }}
          value={formik.values[name].min.toLocaleString()}
          variant='outlined'
        />
        <TextField
          id={id}
          InputLabelProps={{ shrink: true }}
          label={labelMax}
          name={maxName}
          onBlur={formik.handleBlur}
          onChange={(e) => handleChangeMax(e)}
          placeholder={array.max.toLocaleString()}
          size='small'
          sx={{
            '&& .MuiOutlinedInput-notchedOutline': {
              borderRadius: 16,
            },
          }}
          value={formik.values[name].max.toLocaleString()}
          variant='outlined'
        />
      </Box>
    </div>
  );
}
