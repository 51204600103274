/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { TextField, Autocomplete, Typography } from '@mui/material';

import '../filterDropdown/FilterDropdown.css';
import handleRenderOptions from './handleRenderOptions';

export default function FilterText({ formik, label, name, id, completeOpts, setCompleteOpts, loading }) {
  const handleAcChange = (e, val) => {
    if (val) {
      formik.setFieldValue('name', val);
    }
    if (!val) {
      if (e) {
        const { value } = e.target;
        formik.setFieldValue('name', value);
      }
    }
  };

  return (
    <div className='range-wrap'>
      <Typography sx={{ width: '100%', fontWeight: 600, fontSize: 14, pb: '15px' }}>Enter name</Typography>
      <Autocomplete
        autoHighlight
        freeSolo
        getOptionLabel={(option) => option?.toString()}
        id={id}
        loading={loading}
        name={name}
        onChange={(e, val) => handleAcChange(e, val)}
        onClose={() => {
          setCompleteOpts([]);
        }}
        onInputChange={(e, val) => handleAcChange(e, val)}
        options={completeOpts || []}
        renderInput={(params) => (
          <TextField
            id={id}
            label={label}
            name={name}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            size='small'
            sx={{
              '&& .MuiOutlinedInput-notchedOutline': {
                borderRadius: 16,
              },
            }}
            value={formik?.values?.name}
            variant='outlined'
            {...params}
          />
        )}
        renderOption={handleRenderOptions}
        size='small'
        sx={{
          '&& .MuiOutlinedInput-root': {
            width: 280,
          },
        }}
        value={formik.values.name}
      />
    </div>
  );
}
