import { setIsLogin } from '../store/isLogin'
import { addError } from '../store/errorSlice'
import { addFlag } from '../store/loadFlag'
import errorHandler from './errorHandlerTokenError'

const errorRedirectWithStore = (e, dispatch, rejectWithValue) => {
  if (e.message == 401 || e.message == 403 || e?.toString().includes('401') || e?.toString().includes('403')) {
    localStorage.removeItem('mls')
    dispatch(setIsLogin(false))
  }
  const message = errorHandler(e.message)
  dispatch(addError({ message, status: e.message }))
  dispatch(addFlag(false))
  return rejectWithValue(e)
}

export default errorRedirectWithStore
