/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getTable } from '../api/getTable';

export const offersArray = createSlice({
  name: 'offers',
  initialState: {
    offers: [],
    status: null,
    error: null,
    scrollToIndex: undefined,
    hasData: false,
    scrollY: 0,
    offset: 0,
    limit: 30,
    rows: [],
    isLoading: false,
  },
  reducers: {
    addOfferFav: (state, action) => {
      state.rows = state.rows.map((el) => {
        if (el._id === action.payload) {
          return { ...el, isFavorite: true };
        }
        return el;
      });
    },
    removeOfferFav: (state, action) => {
      state.rows = state.rows.map((el) => {
        if (el._id === action.payload) {
          return { ...el, isFavorite: false };
        }
        return el;
      });
    },
    setScrollY: (state, { payload }) => {
      state.scrollY = payload;
    },
    clearRows: (state) => {
      state.rows = [];
    },
  },
  extraReducers: {
    [getTable.pending]: (state) => {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
    },
    [getTable.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.offers = action.payload;
      const { rows, offset } = action.payload;
      for (let i = 0; i < rows.length; i += 1) {
        state.rows[offset + i] = rows[i];
      }
      state.isLoading = false;
      state.hasData = true;
    },
    [getTable.rejected]: (state, action) => {
      state.status = 'rejected';
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default offersArray.reducer;
export const { addOfferFav, removeOfferFav, setScrollY, clearRows } = offersArray.actions;
