import * as React from 'react';
import { Typography, FormLabel, FormGroup, Chip } from '@mui/material';

import '../filterDropdown/FilterDropdown.css';

export default function FilterMultiSelect({ label, array, array2, formik, filterName }) {
  const arrayToShow = array2?.staticOptions?.length > 0 ? array2.staticOptions : array;

  const handleChange = (key, el) => {
    if (!formik?.values?.[key]?.includes(el)) {
      formik.setFieldValue(key, [...formik.values[key], el]);
    } else {
      formik.setFieldValue(
        key,
        formik.values?.[key].filter((v) => v !== el)
      );
    }
  };

  return (
    <FormGroup>
      <FormLabel component='legend'>
        <Typography sx={{ width: '100%', fontWeight: 600, color: '#000', pb: '16px' }}>{label}</Typography>
      </FormLabel>
      <ul className='chipWrap'>
        {arrayToShow.map((el) => (
          <li key={el} style={{ display: 'inline-block' }}>
            <Chip
              color='secondary'
              id={el}
              label={el}
              name={el}
              onClick={() => handleChange(filterName, el)}
              variant={formik?.values?.[filterName]?.includes(el) ? 'filled' : 'outlined'}
            />
          </li>
        ))}
      </ul>
    </FormGroup>
  );
}
