import { createSlice } from '@reduxjs/toolkit';

export const filterOfferSlice = createSlice({
  name: 'filterOffer',
  initialState: [],
  reducers: {
    addFilterOffer: (state, action) => action.payload,
    removeFilterOffer: () => [],
  },
});

export default filterOfferSlice.reducer;
export const { addFilterOffer, removeFilterOffer } = filterOfferSlice.actions;
