/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List } from 'react-window';

import RowRendererLogs from '../tableLogsComponents/RowRendererLogs';
import ShowTotals from '../../showTotals/ShowTotals';
import HeaderRenderer from '../tableLogsComponents/HeaderRenderer';
import { tableLogsWidth } from '../../../utils/constants';
import { getLogs } from '../../../api/getLogs';

import './TableLogs.css';

export default function TableLogs() {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(0);
  const filters = useSelector((state) => state?.filtersLog);
  const isLoading = useSelector((state) => state?.logs?.isLoading);
  const logObject = useSelector((state) => state?.logs?.logs);
  const rowsData = useSelector((state) => state?.logs?.rows);
  const data = rowsData;
  const flag = useSelector((state) => state?.flag);

  const totalQty = logObject?.total || 0;
  const grandTotalQty = logObject?.grandTotal;

  useEffect(() => {
    dispatch(getLogs({ filters }));
  }, [filters]);

  const tableHeight = window.innerHeight - height - 110;

  const rows =
    data && data.length > 0
      ? data.map((item) => ({
          ...item,
          message: JSON.stringify(item?.message).replaceAll('"', ''),
          created: new Date(item.createdAt).toLocaleString('en', {
            hour12: false,
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }),
        }))
      : [];

  const loadMoreItems = (startIndex, stopIndex) => {
    dispatch(getLogs({ filters, offset: startIndex, limit: stopIndex - startIndex }));
  };

  const hasNextPage = true;
  const isItemLoaded = (index) => !hasNextPage || index < rows.length;

  const RowRender = ({ index, style }) => (
    <RowRendererLogs
      {...{
        index,
        style,
        rows,
      }}
    />
  );

  return (
    <div className='object-wrapper'>
      <div>
        <ShowTotals flag={flag} grandTotalQty={grandTotalQty} page='Objects' totalQty={totalQty} />
      </div>
      <div>
        <HeaderRenderer />
      </div>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={totalQty}
        loadMoreItems={loadMoreItems}
        minimumBatchSize={30}
      >
        {({ onItemsRendered, ref }) => (
          <List
            ref={ref}
            height={tableHeight}
            itemCount={totalQty}
            itemData={rows}
            itemSize={100}
            onItemsRendered={onItemsRendered}
            overscanCount={20}
            width={tableLogsWidth}
          >
            {RowRender}
          </List>
        )}
      </InfiniteLoader>
    </div>
  );
}
