import { useState, useEffect } from 'react';
import { Box, Tab } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import ImageGallery from 'react-image-gallery';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { showPriceRange } from '../../utils/functions';
import './EstateBigTile.css';
import StatusLabel from '../statusLable/StatusLabel';
import config from '../../config';
import toggleFavsApi from '../../api/toggleFavs';
import bed from '../../images/bed1.svg';
import bath from '../../images/bath.svg';
import rooms from '../../images/rooms.svg';
import livingSq from '../../images/livingSq.svg';
import area from '../../images/area.svg';
import { addToFavs, removeFromFavs } from '../../store/allObjects';
import { removeFromFavsStore, addToFavsStore } from '../../store/allFavs';

const baseURL = config.baseURL + config.storage;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function EstateBigTile({ home, favs }) {
  const [value, setValue] = useState(0);
  const [priceOverall, setPriceOverall] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // check if there is Objects list in store
  const objectObject = useSelector((state) => state?.objects?.objects?.rows);

  const images =
    home?.offers[value]?.images?.length > 0
      ? home?.offers[value]?.images
          ?.flat()
          ?.map((el) => `${baseURL}${el}`)
          ?.map((el) => ({
            original: el,
            originalClass: 'object-pics',
            originalWidth: '100%',
          }))
      : home?.offers[value]?.originalImages
          ?.flat()
          ?.filter((el) => !el.includes('userfiles'))
          ?.map((el) => ({
            original: el,
            originalClass: 'object-pics',
            originalWidth: '100%',
          }));

  const toggleFavs = (e, id) => {
    e.stopPropagation();
    toggleFavsApi(id);
    if (!favs) {
      if (!home.isFavorite) {
        dispatch(addToFavs(id));
        dispatch(addToFavsStore(home));
      } else {
        dispatch(removeFromFavs(id));
        dispatch(removeFromFavsStore(id));
      }
    }
    if (favs) {
      if (objectObject) {
        dispatch(removeFromFavs(id));
      }
      dispatch(removeFromFavsStore(id));
    }
  };

  const status = home?.offers[value]?.status || home?.status;

  useEffect(() => {
    const data = home;
    const currency = data?.offers?.find((el) => el.currency)?.currency;
    showPriceRange(data, setPriceOverall, currency);
  }, [home]);

  return (
    <div className='single-home-tile'>
      <Box>
        <Box>
          <Tabs
            aria-label='basic tabs example'
            className='tabs-wrapper'
            onChange={handleChange}
            scrollButtons
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
              '&& .MuiTabs-scroller': {
                display: 'flex',
                alignItems: 'center',
              },
              '&& .MuiTab-root': {
                textTransform: 'capitalize',
                px: '12px',
                pb: '3px',
                pt: '4px',
                mr: '10px',
                minHeight: '20px',
              },
              '&& .Mui-selected': {
                backgroundColor: '#7E7CCF',
                color: '#ffffff',
                borderRadius: 10,
              },
              '&& .MuiTabs-indicator': {
                height: 0,
              },
            }}
            value={value}
            variant='scrollable'
          >
            {home?.offers?.map((el) => (
              <Tab key={el.estateObjectDataId} className='tab-label' label={el?.agentName || 'NO NAME'} />
            ))}
          </Tabs>
        </Box>
        {home?.offers?.map((el, index) => (
          <TabPanel key={el.estateObjectDataId} className='tab-panel' index={index} value={value}>
            {images.length > 0 ? (
              <ImageGallery items={images} lazyLoad showFullscreenButton={false} showPlayButton={false} />
            ) : (
              <div className='no-image'>No Image</div>
            )}
            <StatusLabel objectPage status={status} />
          </TabPanel>
        ))}
      </Box>
      <div className='link-div' onClick={() => navigate(`/objects/${home._id}`)} role='link'>
        <Box sx={{ px: 2, position: 'relative' }}>
          {home && home?.name && (
            <div className='home-title'>
              <button
                className={home?.isFavorite ? 'grey-hart' : 'white-hart'}
                onClick={(e) => toggleFavs(e, home._id)}
                type='button'
              />
              <h2>{`${home.offerType}: ${home.name}`}</h2>
            </div>
          )}
          <div className='price-line'>{priceOverall}</div>
          <div className='feats'>
            <div>
              {home && home.roomsCount && (
                <div className='one-feat'>
                  <img alt='room' height={17} src={rooms} width={17} /> &nbsp; &nbsp;&nbsp;
                  <div>{home.roomsCount}</div>
                </div>
              )}
            </div>
            <div>
              {home && home.bedroomsCount && (
                <div className='one-feat'>
                  <img alt='bed' height={20} src={bed} width={21} /> &nbsp; &nbsp;&nbsp;
                  <div>{home.bedroomsCount}</div>
                </div>
              )}
            </div>
            <div>
              {home && home.bathroomsCount && (
                <div className='one-feat'>
                  <img alt='bath' height={19} src={bath} width={19} />
                  &nbsp; &nbsp;&nbsp;
                  <div>{home.bathroomsCount}</div>
                </div>
              )}
            </div>
            <div>
              {home && home.square && (
                <div className='one-feat'>
                  <img alt='living' height={17} src={livingSq} width={18} />
                  &nbsp; &nbsp;&nbsp;
                  <div>{home.square}</div>
                </div>
              )}
            </div>
            <div>
              {home && home.plotSquare && (
                <div className='one-feat'>
                  <img alt='area' src={area} />
                  &nbsp; &nbsp;&nbsp;
                  <div>{home.plotSquare}</div>
                </div>
              )}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default EstateBigTile;
