import * as React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// eslint-disable-next-line react/function-component-definition
const IconToChoose = (changeSelect, item) => {
  if (item === 1) {
    return (
      <button
        className='transparent-button-round'
        onClick={() => {
          changeSelect(-1);
        }}
        type='button'
      >
        <ArrowUpwardIcon sx={{ color: 'black', height: 16 }} />
      </button>
    );
  }
  if (item === -1) {
    return (
      <button
        className='transparent-button-round'
        onClick={() => {
          changeSelect('');
        }}
        type='button'
      >
        <ArrowDownwardIcon sx={{ color: 'black', height: 16 }} />
      </button>
    );
  }
  if (item === '' || !item) {
    return (
      <button
        className='transparent-button-round'
        onClick={() => {
          changeSelect(1);
        }}
        type='button'
      >
        <ArrowUpwardIcon sx={{ color: 'gray', height: 16 }} />
      </button>
    );
  }
};

export default IconToChoose;
