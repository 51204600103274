import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';

import './CellStyles.css';

export function CommonCell({ cellData }) {
  return (
    <TableCell className='common-cell' component='div' style={{ margin: '10px', border: 'none' }}>
      {cellData ? <Typography>{cellData}</Typography> : <Typography>–</Typography>}
    </TableCell>
  );
}

export function MessageCell({ cellData }) {
  return (
    <TableCell component='div' role='button' style={{ cursor: 'pointer', border: 'none' }} variant='body'>
      <PopupState popupId='demoPopover' variant='popover'>
        {(popupState) => (
          <div className='title-cell'>
            <Typography {...bindHover(popupState)}>{cellData ? cellData.slice(0, 90) : '–'}</Typography>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              disableScrollLock
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography style={{ width: '450px', padding: '30px' }}>{cellData}</Typography>
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </TableCell>
  );
}
