import { createSlice } from '@reduxjs/toolkit';

export const isLogin = createSlice({
  name: 'logged',
  initialState: false,
  reducers: {
    setIsLogin: (state, action) => action.payload,
  },
});

export default isLogin.reducer;
export const { setIsLogin } = isLogin.actions;
