import { useSelector, useDispatch } from 'react-redux';
import { Typography, MenuItem, Button } from '@mui/material';

import FilterText from '../filterElements/FilterText';
import FilterMultiSelect from '../filterElements/FilterMultiSelect';
import FilterRange from '../filterElements/FilterRange';
import SortOptions from '../../Sorting/sortOptions/SortOptions';

export default function FilterMenu({
  filterFeats,
  formik,
  filterOptions,
  clearFilter,
  handleClose,
  setTyping,
  completeOpts,
  setCompleteOpts,
  loading,
}) {
  const sort = useSelector((state) => state.sortObject);
  const dispatch = useDispatch();

  return (
    <>
      <h2 className='filters-title'>Sorting:</h2>
      <SortOptions dispatch={dispatch} selectedSorters={sort} />
      <h2 className='filters-title'>Filters:</h2>
      {filterFeats &&
        filterOptions &&
        filterFeats.map((el) => {
          switch (el.type) {
            case 'RANGE':
              return (
                <MenuItem key={el.key}>
                  <FilterRange
                    array={filterOptions[el.key]}
                    formik={formik}
                    id={el.key}
                    label={`#${el.name}`}
                    labelMax={`max ${el.key}`}
                    labelMin={`min ${el.key}`}
                    name={el.key}
                    setTyping={setTyping}
                    type={el.type}
                  />
                </MenuItem>
              );
            case 'MULTISELECT':
              return (
                <MenuItem key={el.key}>
                  <FilterMultiSelect
                    key={el.key}
                    array={filterOptions[el.key]}
                    array2={filterFeats.find((x) => x.key === el.key)}
                    filterName={el.key}
                    formik={formik}
                    id={el.key}
                    label={el.name}
                    name={el.key}
                  />
                </MenuItem>
              );
            default:
              return (
                <MenuItem key={el.key}>
                  <FilterText
                    key={el.key}
                    completeOpts={completeOpts}
                    formik={formik}
                    label={el.name}
                    loading={loading}
                    name={el.key}
                    setCompleteOpts={setCompleteOpts}
                    type={el.type}
                  />
                </MenuItem>
              );
          }
        })}
      <MenuItem className='buttonSet'>
        <Button
          className='transparent-button-filter'
          onClick={() => {
            handleClose();
          }}
          type='button'
        >
          <Typography> Apply all filters</Typography>
        </Button>
        <Button
          className='transparent-button-filter'
          onClick={() => {
            clearFilter();
            handleClose();
          }}
          type='button'
        >
          <Typography>Clear selection</Typography>
        </Button>
      </MenuItem>
    </>
  );
}
